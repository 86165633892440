import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'

import { AuthHandlerComponent } from './auth-handler.component'
import { routing } from './auth-handler.routing'

@NgModule({
  declarations: [AuthHandlerComponent],
  imports: [CommonModule, routing],
  exports: [AuthHandlerComponent],
})
export class AuthHandlerModule {}
