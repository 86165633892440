import { Injectable } from '@angular/core'
import { CanActivate, Router } from '@angular/router'
import { from, Observable, of } from 'rxjs'
import { switchMap, take } from 'rxjs/operators'

import { AuthService } from './auth.service'

@Injectable()
export class NotAuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  public canActivate(): Observable<boolean> {
    return this.authService.currentUser$.pipe(
      take(1),
      switchMap((user) => {
        if (user) {
          return from(this.router.navigate(['budget']))
        }
        return of(true)
      }),
    )
  }
}
