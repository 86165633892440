import { Uuid } from '@dandi/common'
import { HalModelBase, ResourceId } from '@dandi/hal'
import { ArrayOf, Json, ModelBase, Property, Required } from '@dandi/model'
import { DateTime } from 'luxon'

import { BudgetItemCreationModel, BudgetItemResource } from './budget-item'
import { PlanYearlyAmounts, SharingMode } from './plan'

export class ProcessedItem extends ModelBase {
  public static readonly resourceKey: string = 'ProcessedItem'

  constructor(source?: Partial<ProcessedItem>) {
    super(source)
  }

  @Required()
  public item: BudgetItemCreationModel

  @Property(DateTime)
  @Required()
  public processedOn: DateTime

  @Property(Number)
  @Required()
  public balance: number

  @Property(Number)
  @Required()
  public overage: number
}

export class SimulationDebugData extends HalModelBase {
  public static readonly resourceKey: string = 'SimulationDebugData'

  constructor(source?: Partial<SimulationDebugData>) {
    super(source)
  }

  @ArrayOf(BudgetItemResource)
  public bills: BudgetItemCreationModel[]

  @ArrayOf(BudgetItemResource)
  public deposits: BudgetItemCreationModel[]

  @ArrayOf(ProcessedItem)
  public processedItems: ProcessedItem[]
}

export class SimulationResult extends HalModelBase implements PlanYearlyAmounts {
  public static readonly resourceKey: string = 'SimulationResult'

  constructor(source?: Partial<SimulationResult>) {
    super(source)

    if (this.depositContributions && !(this.depositContributions instanceof Map)) {
      this.depositContributions = Object.keys(this.depositContributions).reduce((result, key) => {
        result.set(key, this.depositContributions[key])
        return result
      }, new Map<Uuid, number>())
    }
  }

  @Property(Number)
  @Required()
  public requiredStartingBalance: number

  @Property(DateTime)
  @Required()
  public endDate: DateTime

  @Property(Boolean)
  @Required()
  public isStable: boolean

  // @Property(Map)
  @Required()
  public depositContributions: Map<Uuid, number>

  @Property(Number)
  @Required()
  public yearlyTotalBills: number

  @Property(Number)
  @Required()
  public yearlyTotalDeposits: number

  public toJSON(): object {
    let depositContributions: object = this.depositContributions
    if (this.depositContributions instanceof Map) {
      depositContributions = [...this.depositContributions].reduce((result, [key, value]) => {
        result[key.toString()] = value
        return result
      }, {})
    }
    return Object.assign({}, this, {
      depositContributions,
    })
  }
}

export class SimulationOutput extends HalModelBase {
  public static readonly resourceKey: string = 'SimulationOutput'

  constructor(source?: Partial<SimulationOutput>) {
    super(source)

    if (this.result && !(this.result instanceof SimulationResult)) {
      this.result = new SimulationResult(this.result)
    }

    if (this.data && !(this.data instanceof SimulationDebugData)) {
      this.data = new SimulationDebugData(this.data)
    }
  }

  @Json()
  @Property(SimulationResult)
  public result: SimulationResult

  @Json()
  @Property(SimulationDebugData)
  public data: SimulationDebugData
}

export class SimulationRequestOptions extends ModelBase {
  public static readonly resourceKey: string = 'SimulationRequestOptions'

  constructor(source?: Partial<SimulationRequestOptions>) {
    super(source)
  }

  @Property(Number)
  public startingBalance?: number

  @Property(DateTime)
  public startsOn?: DateTime

  @Property(Number)
  public safetyNet?: number

  @Property(Number)
  public sharingMode?: SharingMode = SharingMode.proportional
}

export class SimulationRequest extends HalModelBase {
  public static readonly resourceKey: string = 'SimulationRequest'

  constructor(source?: Partial<SimulationRequest>) {
    super(source)
  }

  @Property(String)
  @Required()
  @ResourceId()
  public simulationId: string

  @Property(Uuid)
  @Required()
  public userId: Uuid

  @Property(SimulationRequestOptions)
  public options?: SimulationRequestOptions
}

export class SimulationRun extends SimulationRequest {
  public static readonly resourceKey: string = 'SimulationRun'

  constructor(source?: Partial<SimulationRun>) {
    super(source)
  }

  @Property(SimulationOutput)
  @Json()
  public output: SimulationOutput
}

export class Simulation extends SimulationRequest {
  public static readonly resourceKey: string = 'Simulation'

  constructor(source?: Partial<Simulation>) {
    super(source)
  }

  @Property(DateTime)
  @Required()
  public runOn: DateTime

  @Property(SimulationResult)
  @Json()
  public result: SimulationResult

  @ArrayOf(ProcessedItem)
  @Json()
  public overageItems: ProcessedItem[]

  @Property(SimulationDebugData)
  @Json()
  public debugData: SimulationDebugData

  public toJSON(): object {
    return {
      simulationId: this.simulationId,
      userId: this.userId,
      options: this.options,
      runOn: this.runOn,
      result: this.result,
      overageItems: this.overageItems,
      debugData: this.debugData,
    }
  }
}

export class UserSimulation extends Simulation {
  public static readonly resourceKey: string = 'UserSimulation'

  constructor(source?: Partial<UserSimulation>) {
    super(source)
  }
}
