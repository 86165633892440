import { InjectionToken } from '@angular/core'
import { HalModelBuilder } from '@dandi/hal-model-builder'
import {
  BooleanTypeConverter,
  DateTimeTypeConverter,
  ModelBuilder,
  NumberTypeConverter,
  PrimitiveTypeConverter,
  StringTypeConverter,
  UrlTypeConverter,
  UuidTypeConverter,
} from '@dandi/model-builder'

export const MODEL_BUILDER_INSTANCE = new HalModelBuilder(
  new PrimitiveTypeConverter([
    new NumberTypeConverter(),
    new BooleanTypeConverter(),
    new StringTypeConverter(),
    new DateTimeTypeConverter(),
    new UrlTypeConverter(),
    new UuidTypeConverter(),
  ]),
)

export const DandiModelBuilder = new InjectionToken<ModelBuilder>('@dandi/model-builder')
