import { Injectable, InjectionToken, StaticProvider, Type } from '@angular/core'

import { AnyCondition, ConditionCompletion, isManualCondition, ManualCondition } from '../'

import { InjectorCompletionStateFactory } from './injector-completion-state-factory'

@Injectable()
export class ManualConditionCompletionStateFactory extends InjectorCompletionStateFactory<ManualCondition> {
  protected canHandleCondition(condition: AnyCondition): condition is ManualCondition {
    return isManualCondition(condition)
  }

  protected getProviders(condition: ManualCondition): StaticProvider[] {
    return condition.providers
  }

  protected getServiceClass(
    condition: ManualCondition,
  ): InjectionToken<ConditionCompletion> | Type<ConditionCompletion> {
    return condition.complete
  }
}
