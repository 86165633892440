import { ModuleWithProviders } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'

import { LazyModule } from '../shared/app-common'
import { AuthGuard, NotAuthGuard } from '../shared/auth'

export const routes: Routes = [
  // pathMatch: 'full' required on "home" route to ensure that it is only loaded
  // when the location is / - otherwise it will match for every route
  {
    path: '',
    canActivate: [NotAuthGuard],
    loadChildren: (): LazyModule => import('../home/home.module').then((m) => m.HomeModule),
    pathMatch: 'full',
  },
  {
    path: 'account',
    canActivate: [AuthGuard],
    loadChildren: (): LazyModule => import('../account/account.module').then((m) => m.AccountModule),
    data: { title: 'Account', fullScreenRoute: 'mobile' },
  },
  {
    path: 'budget',
    canActivate: [AuthGuard],
    loadChildren: (): LazyModule => import('../budget/budget.module').then((m) => m.BudgetModule),
  },
  { path: 'budgets', redirectTo: 'budget' },
  {
    path: 'theme',
    loadChildren: (): LazyModule => import('../theme/theme.module').then((m) => m.ThemeModule),
  },
  {
    path: 'legal',
    loadChildren: (): LazyModule => import('../legal/legal.module').then((m) => m.LegalModule),
  },
  {
    path: 'support',
    loadChildren: (): LazyModule => import('../support/support.module').then((m) => m.SupportModule),
  },
]

export const routing: ModuleWithProviders = RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })
