import { Injectable } from '@angular/core'
import { ActivatedRoute, ActivatedRouteSnapshot, Data, NavigationEnd, Params, Router } from '@angular/router'
import { combineLatest, Observable } from 'rxjs'
import { filter, map, shareReplay } from 'rxjs/operators'

import { Breakpoint, ResponsiveService } from '../../positioning'

export interface RouteData {
  fullScreen: boolean
  heightConstrained: boolean
  layered: boolean
  path: string[]
  params?: Params
  queryParams?: Params
  title?: string
  titleSuffix?: string
}

export interface RouteInfo {
  params?: Params
  queryParams?: Params
  data?: Data
  path?: string[]
}

const ROUTE_DATA_DEFAULTS: RouteData = {
  fullScreen: false,
  heightConstrained: false,
  layered: false,
  path: [],
}

@Injectable({ providedIn: 'root' })
export class RouteHelper {
  public readonly snapshot$: Observable<ActivatedRouteSnapshot>
  public readonly routeData$: Observable<RouteData>

  constructor(route: ActivatedRoute, router: Router, private readonly responsive: ResponsiveService) {
    this.snapshot$ = router.events.pipe(
      filter((e) => e instanceof NavigationEnd),
      map(() => route.snapshot),
      shareReplay(1),
    )
    this.routeData$ = combineLatest([this.snapshot$, responsive.isAtLeast(Breakpoint.lg)]).pipe(
      map(([snapshot, isLargeDevice]) => this.getRouteData(snapshot, isLargeDevice)),
      shareReplay(1),
    )
  }

  public getRouteTitle(title: string, titleSuffix?: string): string {
    if (!title) {
      return ''
    }
    return `${title}${titleSuffix ? ' ' : ''}${titleSuffix || ''}`
  }

  public getRouteData(snapshot: ActivatedRouteSnapshot, isLargeDevice: boolean): RouteData {
    const info = this.collectRouteInfo(snapshot)
    return Object.assign(
      { title: this.getRouteTitle(info.data.title, info.data.titleSuffix) },
      ROUTE_DATA_DEFAULTS,
      info.data,
      info,
      {
        fullScreen: info.data.fullScreenRoute === 'mobile' ? !isLargeDevice : info.data.fullScreenRoute,
      },
    )
  }

  private collectRouteInfo(snapshot: ActivatedRouteSnapshot): RouteInfo {
    const childInfo = snapshot.firstChild ? this.collectRouteInfo(snapshot.firstChild) : {}
    const data = Object.assign({}, snapshot.data, childInfo.data)
    const params = Object.assign({}, snapshot.params, childInfo.params)
    const queryParams = Object.assign({}, snapshot.queryParams, childInfo.queryParams)
    const path = (snapshot.url.map((seg) => seg.path) || []).concat(childInfo.path || [])
    return { data, params, queryParams, path }
  }
}
