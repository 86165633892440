import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Observable } from 'rxjs'
import { mergeMap } from 'rxjs/operators'

import { AuthAccessor } from './auth.accessor'

export class AuthInterceptor implements HttpInterceptor {
  constructor(private pattern: RegExp, private authAccessor$: AuthAccessor) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!req.url.match(this.pattern)) {
      return next.handle(req)
    }
    return this.authAccessor$.pipe(
      mergeMap((authorization) =>
        next.handle(
          req.clone({
            setHeaders: {
              Authorization: authorization,
            },
          }),
        ),
      ),
    )
  }
}
