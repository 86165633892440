import { InjectionToken } from '@angular/core'

import { Responsive } from './responsive'

export type BreakpointsConfig = Responsive<number>

export interface ResponsiveConfig {
  breakpoints: BreakpointsConfig
}

export const ResponsiveConfig = new InjectionToken<ResponsiveConfig>('ResponsiveConfig')
