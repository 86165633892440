import { InjectionToken, StaticProvider, Type } from '@angular/core'

import { ConditionCompletion } from './condition-completion'

export interface ManualCondition {
  complete: InjectionToken<ConditionCompletion> | Type<ConditionCompletion>
  providers?: StaticProvider[]
}

export function isManualCondition(obj: any): obj is ManualCondition {
  return typeof obj?.complete === 'function' || obj?.complete instanceof InjectionToken
}
