import { Component } from '@angular/core'

import { AuthService } from './auth.service'

@Component({
  selector: 'auth-info',
  templateUrl: 'auth-info.component.pug',
  styleUrls: ['auth-info.component.scss'],
})
export class AuthInfoComponent {
  public readonly user$ = this.authService.currentUser$

  constructor(private authService: AuthService) {}
}
