import { DOCUMENT } from '@angular/common'
import { Inject, Injectable } from '@angular/core'
import { Title } from '@angular/platform-browser'

@Injectable({ providedIn: 'root' })
export class AppTitle extends Title {
  constructor(@Inject(DOCUMENT) document) {
    super(document)
  }

  public setTitle(title: string): void {
    super.setTitle(`Figure My Bills${title ? ' - ' : ''}${title}`)
  }
}
