import { Axis } from './axis'
import { PositionEdge } from './position-dimensions'

export interface RelativePosition {
  readonly name: keyof RelativePositionStatic
  readonly axis: Axis
  readonly edge: PositionEdge
  readonly defaultAlignment: RelativePosition
}

export interface RelativePositionStatic {
  readonly top: RelativePosition
  readonly bottom: RelativePosition
  readonly left: RelativePosition
  readonly right: RelativePosition
}

class RelativePositionImpl {
  public static readonly top = new RelativePositionImpl('top', Axis.y, PositionEdge.leading)
  public static readonly bottom = new RelativePositionImpl('bottom', Axis.y, PositionEdge.trailing)
  public static readonly left = new RelativePositionImpl('left', Axis.x, PositionEdge.leading)
  public static readonly right = new RelativePositionImpl('right', Axis.x, PositionEdge.trailing)

  public get defaultAlignment(): RelativePosition {
    if (this.axis === Axis.x) {
      return RelativePosition.top
    }
    return RelativePosition.left
  }

  private constructor(
    public readonly name: keyof RelativePositionStatic,
    public readonly axis: Axis,
    public readonly edge: PositionEdge,
  ) {}

  public toString(): string {
    return this.name
  }
}

export const RelativePosition: RelativePositionStatic = RelativePositionImpl
