import { Directive, HostBinding, HostListener, Input } from '@angular/core'

@Directive({
  selector: 'a[disabled]',
})
export class LinkDisabledDirective {
  @Input('disabled')
  @HostBinding('class.disabled')
  public disabled: boolean

  @HostListener('mousedown', ['$event'])
  private onClick(e: MouseEvent): boolean {
    if (this.disabled) {
      e.preventDefault()
      e.stopPropagation()
      return false
    }
    return true
  }
}
