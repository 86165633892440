import { ModuleWithProviders, NgModule } from '@angular/core'

import { BreakpointsConfig, ResponsiveConfig } from './responsive-config'
import { ResponsiveBrowserWindow, ResponsiveWindow } from './responsive-window'

@NgModule({
  providers: [{ provide: ResponsiveWindow, useClass: ResponsiveBrowserWindow }],
})
export class PositioningModule {
  public static config(breakpoints: BreakpointsConfig): ModuleWithProviders<PositioningModule> {
    return {
      ngModule: PositioningModule,
      providers: [{ provide: ResponsiveConfig, useValue: { breakpoints } }],
    }
  }

  public static configBootstrap(): ModuleWithProviders<PositioningModule> {
    return PositioningModule.config({
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
    })
  }
}
