import { ChapterAction, GuideAction, StoryAction } from './guide-action'

export class ResetGuideState extends GuideAction {
  public static readonly type = ResetGuideState.getType('Reset State')
}

export class ResetStoryState extends StoryAction {
  public static readonly type = StoryAction.getType('Reset State')
}

export class ResetChapterState extends ChapterAction {
  public static readonly type = ChapterAction.getType('Reset State')
}
