import { MinValue, ModelBase, Property, Required } from '@dandi/model'
import { DateTime } from 'luxon'

import { IntervalType, IntervalTypeYearlyMultiplier } from './interval-type'

export interface BillIntervalModel {
  type: IntervalType
  value: number
  nextDueOn: number | DateTime
}

export interface BillIntervalModelStatic {
  getNextDueOn(interval?: BillIntervalModel): DateTime
}

export const BillIntervalModel: BillIntervalModelStatic = {
  getNextDueOn(interval?: BillIntervalModel): DateTime {
    if (interval?.nextDueOn === undefined || interval?.nextDueOn === null) {
      return undefined
    }
    if (interval.nextDueOn instanceof DateTime) {
      return interval.nextDueOn
    }
    return DateTime.fromMillis(interval.nextDueOn)
  },
}

export class BillInterval extends ModelBase implements BillIntervalModel {
  public static readonly resourceKey: string = 'BillInterval'

  constructor(source?: BillIntervalModel) {
    super(source)

    if (this.nextDueOn) {
      if (this.nextDueOn instanceof Date) {
        this.nextDueOn = DateTime.fromJSDate(this.nextDueOn)
      } else {
        switch (typeof this.nextDueOn) {
          case 'string':
            this.nextDueOn = DateTime.fromISO(this.nextDueOn as any)
            break
        }
      }
    }
  }

  @Property(Number)
  @Required()
  public type: IntervalType

  @Property(Number)
  @Required()
  @MinValue(1)
  public value: number

  @Property(DateTime)
  @Required()
  public nextDueOn: DateTime

  public valueOf(): number {
    return IntervalTypeYearlyMultiplier[this.type] / this.value
  }
}
