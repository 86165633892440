import { Inject } from '@angular/core'
import { Injectable } from '@angular/core'
import { combineLatest, defer, Observable, race } from 'rxjs'
import { distinctUntilChanged, map } from 'rxjs/operators'

import { sustained } from '../../rxjs'

import { AnyCondition } from '../any-condition'

import { ConditionCompletionStateFactory } from './condition-completion-state-factory'

@Injectable({ providedIn: 'root' })
export class ConditionService {
  constructor(@Inject(ConditionCompletionStateFactory) private readonly factories: ConditionCompletionStateFactory[]) {}

  public getFactories(condition: AnyCondition): Observable<boolean> {
    return race(this.factories.map((factory) => factory.getComplete(condition)))
  }

  public getConditionsMet(conditions: AnyCondition[]): Observable<boolean> {
    if (!conditions?.length) {
      console.log(`[${this.constructor.name}] conditionsMet no conditions`)
      return sustained(true)
    }

    return defer(() => {
      const conditionsMet = conditions.map((condition) => this.getFactories(condition))
      console.log(`[${this.constructor.name}] conditionsMet`, conditions)
      return combineLatest(conditionsMet)
    }).pipe(
      map((conditionCompletion) => {
        console.log(`[${this.constructor.name}] conditionsMet`, conditionCompletion)
        return conditionCompletion.every((met: boolean) => met)
      }),
      distinctUntilChanged(),
    )
  }
}
