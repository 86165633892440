import { HttpClientModule } from '@angular/common/http'
import { ErrorHandler, NgModule } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { BudgetResource, BudgetItemResource, Me, BudgetRelations, BudgetItemRelations } from '@fmb/models'
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin'
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin'
import { NgxsRouterPluginModule } from '@ngxs/router-plugin'
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin'
import { NgxsModule } from '@ngxs/store'

import { AuthHandlerModule } from '../auth-handler'
import { BudgetState } from '../budget'
import { SimulationState } from '../budget/plan'
import { APP_CONFIG_PROVIDER } from '../config'
import { environment } from '../environments/environment'
import { GuideModule, GuideState } from '../guide'
import { HomeModule } from '../home'
import { MeState } from '../me'
import { PositioningModule } from '../positioning'
import { ApiHostInterceptor } from '../shared'
import { AuthInfoModule, AuthModule, FirebaseAuthInterceptor } from '../shared/auth'
import {
  AppCommonModule,
  AppTitle,
  DandiModelBuilder,
  ErrorsProviders,
  MODEL_BUILDER_INSTANCE,
  ModelSerializer,
} from '../shared/app-common'
import { WalkthroughStoryModule } from '../walkthrough'

import { AppComponent } from './app.component'
import { routing } from './app.routing'
import { AppErrorComponent } from './app-error.component'
import { AppErrorHandler } from './app-error-handler'
import { AppNavigationComponent } from './app-navigation.component'
import { guideUserKeyProvider } from './guide-user-key'

const PRODUCTION = environment.production

@NgModule({
  declarations: [AppComponent, AppErrorComponent, AppNavigationComponent],
  imports: [
    AppCommonModule,
    AuthHandlerModule,
    AuthInfoModule,
    AuthModule,
    BrowserAnimationsModule,
    PositioningModule.configBootstrap(),
    GuideModule,
    HomeModule,
    HttpClientModule,
    NgxsModule.forRoot([BudgetState, GuideState, MeState, SimulationState]),
    // FIXME: refactor state object mutations so this can be enabled
    // NgxsModule.forRoot([BudgetState, MeState, SimulationState], { developmentMode: !PRODUCTION }),
    NgxsLoggerPluginModule.forRoot(),
    NgxsStoragePluginModule.forRoot({
      key: ['guide'],
    }),
    NgxsRouterPluginModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    WalkthroughStoryModule,
    routing,
  ],
  providers: [
    APP_CONFIG_PROVIDER,
    // override the built-in Title service to use our own
    { provide: Title, useClass: AppTitle },
    ApiHostInterceptor.provider,
    ErrorsProviders,
    FirebaseAuthInterceptor,
    { provide: DandiModelBuilder, useValue: MODEL_BUILDER_INSTANCE },
    { provide: ErrorHandler, useClass: AppErrorHandler },
    { provide: AppErrorHandler, useExisting: ErrorHandler },
    guideUserKeyProvider,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

ModelSerializer.register(
  Me,
  BudgetResource,
  BudgetItemResource,

  // IMPORTANT: reference relations classes so they don't get tree-shaken
  BudgetRelations,
  BudgetItemRelations,
)
