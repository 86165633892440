export type ActionStatic<TAction = any> = {
  type: string
  new (...args: any[]): TAction
}

export type ActionType<TActionCtr> = TActionCtr extends ActionStatic<infer TActionType> ? TActionType : never

export interface ActionCondition<TAction extends ActionStatic = ActionStatic> {
  actionType: TAction
  actionFilter?: Partial<ActionType<TAction>>
}

export function isActionCondition(obj: any): obj is ActionCondition {
  return (typeof obj?.actionType === 'function' && !!obj.actionType.type) || false
}
