import { ElementRef, InjectionToken, ViewContainerRef } from '@angular/core'
import { Observable } from 'rxjs'

import { ChapterStepComponent } from './chapter-step.component'
import { BeaconStep, ButtonStep, Chapter, Step } from './model'
import { Story } from './story'

export interface GuideBeacon {
  readonly element: HTMLElement
  readonly elementRef: ElementRef
  readonly name: string
  readonly viewContainerRef: ViewContainerRef
  readonly visible: boolean
  activateStep(story: Story, chapter: Chapter, step: Step, component?: ChapterStepComponent): void
  deactivateStep(story: Story, chapter: Chapter, step: Step): void
  renderStep(
    story: Story,
    chapter: Chapter,
    step: BeaconStep,
    unlocked$: Observable<boolean>,
    complete$: Observable<boolean>,
  ): Observable<boolean>
  stepButton(story: Story, chapter: Chapter, step: ButtonStep): Observable<void>
}

export const GuideBeacon = new InjectionToken<GuideBeacon>('GuideBeacon')
