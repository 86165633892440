interface AnalyticsData {
  category?: string
  action?: string
  label?: string
  value?: number
}

export interface AnalyticsEventFields {
  eventCategory?: string
  eventAction?: string
  eventLabel?: string
  eventValue?: number
}

export class AnalyticsAction {
  protected get analyticsCategory(): string {
    return this._analyticsData?.category
  }

  protected get analyticsAction(): string {
    return this._analyticsData?.action
  }

  protected get analyticsLabel(): string {
    return this._analyticsData?.label
  }

  protected get analyticsValue(): number {
    return this._analyticsData?.value
  }

  constructor(protected readonly _analyticsData?: AnalyticsData) {}

  public get analyticsFields(): AnalyticsEventFields {
    return {
      eventCategory: this.analyticsCategory,
      eventAction: this.analyticsAction,
      eventLabel: this.analyticsLabel,
      eventValue: this.analyticsValue,
    }
  }
}
