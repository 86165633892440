import { Inject, Injectable } from '@angular/core'
import { combineLatest, merge, NEVER, Observable, race } from 'rxjs'
import {
  distinctUntilChanged,
  filter,
  finalize,
  map,
  mapTo,
  mergeMap,
  mergeMapTo,
  shareReplay,
  startWith,
  take,
  tap,
} from 'rxjs/operators'

import { AvailableStep } from './available-step'
import { isAnyCondition } from './condition'
import { ConditionService } from './condition/completion-state'
import { GuideBeaconsService } from './guide-beacons.service'
import { AnyStep, BeaconStep, Chapter, isBeaconStep, Step, Steps } from './model'
import { sustained } from './rxjs'
import { StepCompletionStateFactory } from './step-completion-state'
import { Story } from './story'

type StepCompletionStateMap = { [stepId: string]: boolean }
const shareReplayRef = <T>(bufferSize = 1) => shareReplay<T>({ refCount: true, bufferSize })

@Injectable()
export class ChapterStepsManager {
  public readonly completedSteps$: Observable<Steps>
  public readonly remainingSteps$: Observable<Steps>
  public readonly complete$: Observable<true>
  public readonly steps: AnyStep[]
  public readonly stepMap: Steps

  private readonly boundSteps = new Map<string, Observable<boolean>>()
  private readonly stepCompletionState$: Observable<StepCompletionStateMap>

  constructor(
    private readonly beaconService: GuideBeaconsService,
    private readonly conditions: ConditionService,
    @Inject(StepCompletionStateFactory) private readonly completionStateFactories: StepCompletionStateFactory[],
    @Inject(Story) private readonly story: Story,
    @Inject(Chapter) private readonly chapter: Chapter,
  ) {
    this.stepMap = chapter.steps
    this.steps = Object.values(this.stepMap || {})
    this.autoBindSteps(this.steps)

    this.stepCompletionState$ = this.stepCompletionState()

    this.completedSteps$ = this.completedSteps()
    this.remainingSteps$ = this.remainingSteps()

    const forceCompleteSteps = new Set(this.steps.filter((step) => step.forceChapterComplete).map((step) => step.id))

    this.complete$ = this.stepCompletionState$.pipe(
      filter((steps) => {
        const remaining = []
        for (const [stepId, complete] of Object.entries(steps)) {
          if (complete && forceCompleteSteps.has(stepId)) {
            console.log('[ChapterStepsManager] complete$ true (forceChapterComplete)', chapter.id, stepId, {
              stepId,
              complete,
              steps,
            })
            return true
          }
          if (!complete) {
            remaining.push(stepId)
          }
        }
        console.log('[ChapterStepsManager] complete$', !remaining.length, chapter.id, { remaining, steps })
        return !remaining.length
      }),
      take(1),
      mapTo(true as const),
      finalize(() => {
        console.log('[ChapterStepsManager] complete$ finalize', chapter.id)
      }),
      shareReplayRef(),
    )
  }

  public bindStep(id: string, complete$: Observable<boolean>): this {
    console.log('[ChapterStepsManager] bindStep', this.chapter.id, id)
    this.boundSteps.set(id, complete$)
    return this
  }

  public getStepComplete(available: AvailableStep): Observable<boolean> {
    console.log('[ChapterStepsManager] getStepComplete', this.chapter.id, available.step.id)
    const completinators = this.completionStateFactories.map((factory) => factory.getComplete(available))
    const step = available.step
    if (isAnyCondition(step)) {
      completinators.push(this.conditions.getFactories(step))
    }
    return race(...completinators).pipe(
      tap((complete) =>
        console.log('[ChapterStepsManager] getStepComplete', this.chapter.id, available.step.id, complete),
      ),
      shareReplayRef(),
    )
  }

  private autoBindSteps(steps: AnyStep[]): void {
    const remaining = new Set(steps)
    while (remaining.size) {
      const bindable = [...remaining.values()].filter(
        (step) => !step.dependencies || step.dependencies.every((dep) => this.boundSteps.has(dep)),
      )
      if (!bindable.length) {
        throw new Error('Remaining unbound steps with unmeetable dependencies')
      }
      bindable.forEach((step) => {
        console.log('[ChapterStepsManager] autoBindSteps', this.chapter.id, step.id, {
          isBeaconStep: isBeaconStep(step),
          step,
        })
        const beacon$ = isBeaconStep(step) ? this.beaconService.get(step.beacon) : NEVER

        const conditions$ = this.conditions
          .getConditionsMet(step.conditions)
          .pipe(
            tap((met) =>
              console.log('[ChapterStepsManager] conditions$', this.chapter.id, step.id, met, step.conditions),
            ),
          )

        const deps$ = (step.dependencies?.length
          ? combineLatest(step.dependencies.map((dep) => this.boundSteps.get(dep))).pipe(
              map((stepCompletion) => stepCompletion.every((complete) => complete)),
              distinctUntilChanged(),
            )
          : sustained(true)
        ).pipe(
          tap((complete) =>
            console.log('[ChapterStepsManager] deps$', this.chapter.id, step.id, complete, step.dependencies),
          ),
        )

        // TODO: allow separate "content" and "unlock" conditions? e.g. condition for allowing content to be shown
        const unlocked$ = deps$.pipe(
          mergeMap((depsComplete) => (depsComplete ? conditions$ : sustained(false))),
          distinctUntilChanged(),
          shareReplayRef(1),
        )

        const stepComplete$ = unlocked$.pipe(
          mergeMap((unlocked) => {
            if (unlocked) {
              return this.getStepComplete({ story: this.story, chapter: this.chapter, step, beacon$ })
            }
            return sustained(false)
          }),
          distinctUntilChanged(),
          tap((complete) => console.log('[ChapterStepsManager] complete$', this.chapter.id, step.id, complete)),
        )

        const render$ = beacon$.pipe(
          mergeMap((beacon) => {
            console.log('[ChapterStepsManager] render$', this.chapter.id, step.id, beacon)
            return beacon
              ? beacon.renderStep(this.story, this.chapter, step as BeaconStep, unlocked$, stepComplete$)
              : NEVER
          }),
          mergeMapTo(NEVER),
        )

        const complete$ = merge(stepComplete$, render$).pipe(
          startWith(false),
          finalize(() => {
            console.log('[ChapterStepsManager] complete$ finalize', this.chapter.id, step.id)
          }),
          shareReplayRef(),
        )

        this.bindStep(step.id, complete$)

        remaining.delete(step)
      })
    }
  }

  private stepCompletionState(): Observable<StepCompletionStateMap> {
    console.log('[ChapterStepsManager] stepCompletionState', this.boundSteps)
    const completionStates = [...this.boundSteps.entries()].map(([id, complete$]) =>
      complete$.pipe(map((complete) => [id, complete] as [string, boolean])),
    )
    return combineLatest(completionStates).pipe(
      map((stepCompletionStates) =>
        stepCompletionStates.reduce((result, [id, complete]) => {
          result[id] = complete
          return result
        }, {}),
      ),
      tap((steps) => console.log('[ChapterStepsManager] stepCompletionState$', steps)),
      shareReplayRef(),
    )
  }

  private completedSteps(): Observable<Steps> {
    if (!this.steps.length) {
      return NEVER
    }

    return this.stepCompletionState$.pipe(
      map((stepCompletionStates) => this.steps.filter((step) => stepCompletionStates[step.id])),
      map(this.toStepMap),
      tap((completedSteps) => console.log('completedSteps$', completedSteps)),
      shareReplayRef(),
    )
  }

  private remainingSteps(): Observable<Steps> {
    if (!this.steps.length) {
      return NEVER
    }

    return this.stepCompletionState$.pipe(
      map((stepCompletionStates) => this.steps.filter((step) => !stepCompletionStates[step.id])),
      map(this.toStepMap),
      tap((remainingSteps) => console.log('remainingSteps$', remainingSteps)),
      shareReplayRef(),
    )
  }

  private toStepMap(steps: Step[]): Steps {
    return steps.reduce((result, step) => {
      result[step.id] = step
      return result
    }, {})
  }
}
