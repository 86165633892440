import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'

import { BudgetItemHeadersComponent } from './budget-item-headers.component'
import { BudgetItemComponent } from './budget-item.component'
import { BudgetItemsListComponent } from './budget-items-list.component'
import { BudgetItemsComponent } from './budget-items.component'
import { routing, routingProviders } from './budget-items.routing'
import { EditItemIntervalModule } from './edit-item-interval.module'
import { ItemIntervalComponent } from './item-interval.component'
import { SortableHeaderComponent } from './sortable-header.component'

@NgModule({
  declarations: [
    BudgetItemComponent,
    BudgetItemsComponent,
    BudgetItemsListComponent,
    BudgetItemHeadersComponent,
    ItemIntervalComponent,
    SortableHeaderComponent,
  ],
  imports: [EditItemIntervalModule, CommonModule, ReactiveFormsModule, routing],
  providers: [routingProviders],
})
export class BudgetItemsModule {}
