import { Injectable } from '@angular/core'
import { Event, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router'
import { Observable } from 'rxjs'
import { scan, shareReplay } from 'rxjs/operators'

import { ErrorInfo } from './errors'

export interface NavState {
  loading?: boolean
  error?: ErrorInfo
}

@Injectable({ providedIn: 'root' })
export class NavStateService {
  public readonly navState$: Observable<NavState>

  constructor(router: Router) {
    this.navState$ = router.events.pipe(
      scan((prev: NavState, e: Event) => {
        const error: ErrorInfo =
          e instanceof NavigationError ? { error: e.error, dismissible: false, source: 'NavState' } : undefined
        let loading: boolean = prev?.loading

        if (e instanceof NavigationStart) {
          loading = true
        }

        if (e instanceof NavigationCancel || e instanceof NavigationEnd || e instanceof NavigationError) {
          loading = false
        }

        return { loading, error }
      }, {}),
      shareReplay(1),
    )
  }
}
