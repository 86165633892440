import { Component, HostBinding } from '@angular/core'

import { ModalComponent } from './modal.component'

@Component({
  selector: 'modal-title',
  template: '<ng-content></ng-content>',
  styleUrls: ['./modal-title.component.scss'],
})
export class ModalTitleComponent {
  @HostBinding('class.modal-title')
  public readonly modalTitle = true

  @HostBinding('attr.id')
  public readonly titleId: string

  constructor(modal: ModalComponent) {
    this.titleId = modal.titleElementId
  }
}
