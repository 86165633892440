import { InjectionToken } from '@angular/core'

import { AnyCondition } from '../condition'

import { GuideElementLayout } from './guide-element-type'
import { isStep, Step } from './step'

export interface StepContent extends GuideElementLayout {
  title: string
  body?: string
  conditions?: AnyCondition[]
}

export interface ContentStep extends Step {
  content: StepContent
}

export function isContentStep(obj: any): obj is ContentStep {
  return !!obj?.content && isStep(obj)
}

export const ContentStep = new InjectionToken<ContentStep>('ContentStep')
