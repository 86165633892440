import { Inject, Injectable, NgZone } from '@angular/core'
import { Router } from '@angular/router'
import firebase from '@firebase/app'
import '@firebase/auth'
import {
  AuthProvider,
  EmailAuthProvider,
  FirebaseAuth,
  GoogleAuthProvider,
  TwitterAuthProvider,
  User,
} from '@firebase/auth-types'
import { from, Observable, of, ReplaySubject } from 'rxjs'
import { filter, pluck, shareReplay, switchMap, switchMapTo } from 'rxjs/operators'

import { APP_CONFIG_TOKEN, AppConfig } from '../../config'

import { RouteHelper } from '../app-common'

export const ACCOUNT_EXISTS = 'auth/account-exists-with-different-credential'

export interface AuthProviderInfo {
  create(): AuthProvider
  name: string
  label: string
}

function createEmailAuthProvider(): EmailAuthProvider {
  return new firebase.auth.EmailAuthProvider()
}

function createGoogleAuthProvider(): GoogleAuthProvider {
  const provider = new firebase.auth.GoogleAuthProvider()
  provider.addScope('email')
  provider.addScope('profile')
  return provider
}

function createTwitterAuthProvider(): TwitterAuthProvider {
  return new firebase.auth.TwitterAuthProvider()
}

const AUTH_PROVIDERS: AuthProviderInfo[] = [
  // { name: 'email', label: 'Email', create: createEmailAuthProvider },
  { name: 'google', label: 'Google', create: createGoogleAuthProvider },
  { name: 'twitter', label: 'Twitter', create: createTwitterAuthProvider },
]

@Injectable({ providedIn: 'root' })
export class FirebaseAuthService {
  public readonly authState$: Observable<User>

  public get providers(): AuthProviderInfo[] {
    return AUTH_PROVIDERS
  }

  private readonly firebaseAuth: FirebaseAuth

  constructor(
    private routeHelper: RouteHelper,
    private router: Router,
    private ngZone: NgZone,
    @Inject(APP_CONFIG_TOKEN) private appConfig: AppConfig,
  ) {
    const app = firebase.initializeApp(this.appConfig.firebase)
    this.firebaseAuth = app.auth()

    const authState$$ = new ReplaySubject<User>(1)

    this.authState$ = authState$$.pipe(
      switchMap((user) => (user ? of(user) : from(this.firebaseAuth.getRedirectResult()).pipe(pluck('user')))),
      shareReplay(1),
    )
    this.firebaseAuth.onAuthStateChanged(authState$$)
  }

  public signIn(provider: AuthProvider): Observable<boolean> {
    return from(this.firebaseAuth.signInWithRedirect(provider)).pipe(
      switchMapTo(this.routeHelper.routeData$),
      switchMap((routeData) => {
        if (routeData.queryParams.returnUrl) {
          return from(this.router.navigateByUrl(decodeURIComponent(routeData.queryParams.returnUrl)))
        }
        return from(this.router.navigate(['budgets']))
      }),
    )
  }

  public signOut(): Observable<boolean> {
    return from(this.firebaseAuth.signOut()).pipe(
      switchMapTo(this.authState$),
      filter((user) => !user),
      switchMap(() => from(this.router.navigate(['']))),
    )
  }
}
