import { CreateBudgetItem } from '../action'
import { BudgetItemsService } from '../budget'
import { BudgetItemMode } from '../budget/items'
import { ButtonStepType, ChapterComponent, GuideElementType, Story } from '../guide'
import { LocationCondition, serviceCondition } from '../guide/condition'
import { RelativePosition } from '../positioning'

import { HowThisWorksComponent } from './how-this-works.component'
import { NavigateToAccountStepCompletion } from './navigate-to-account-step-completion'
import { NavigateToNewItemStepCompletion } from './navigate-to-new-item-step-completion'
import { WalkthroughDoneComponent } from './walkthrough-done.component'

const ON_BUDGET_ROUTE: LocationCondition = {
  routePath: ['budget', String],
}

const walkthroughIntro = Story.define('walkthrough', { name: 'Introduction Guide' })
  .chapter('intro', {
    name: 'Introduction',
    unskippable: true,
    component: HowThisWorksComponent,
    beacon: 'guide',
  })
  .step('continue', {
    beacon: 'continue',
    button: ButtonStepType.beacon,
  })

const walkThroughBills = walkthroughIntro
  .chapter(
    'add-bills',
    {
      name: 'Adding Bills',
      component: ChapterComponent,
      beacon: 'edit-item',
      layout: {
        xs: {
          // FIXME: update position bounding so that when the element would exceed the bounds on both side,
          //        favor overlapping in the direction that will not exceed the document bounds
          boundedBy: {
            x: GuideElementType.chapterBeacon,
            y: GuideElementType.beacon,
          },
          position: RelativePosition.top,
        },
        lg: {
          boundedBy: GuideElementType.chapterBeacon,
          position: RelativePosition.right,
        },
      },
    },
    ['intro'],
  )
  .step(
    'navigate',
    NavigateToNewItemStepCompletion.step('bill', {
      content: {
        conditions: [ON_BUDGET_ROUTE],
        title: 'Add a bill',
        layout: {
          xs: {
            boundedBy: GuideElementType.routeComponent,
            positionBoundedBy: {
              x: GuideElementType.beaconParent,
              y: GuideElementType.beacon,
            },
            position: RelativePosition.bottom,
          },
          lg: {
            positionBoundedBy: {
              x: GuideElementType.beacon,
              y: GuideElementType.beaconParent,
            },
            position: RelativePosition.left,
          },
        },
      },
    }),
  )
  .step(
    'item-name',
    {
      beacon: 'edit-item-name',
      content: {
        title: 'Bill name',
        body: 'Enter a short name to help you identify the bill.',
      },
      formControlPath: 'name',
    },
    ['navigate'],
  )
  .step(
    'item-amount',
    {
      beacon: 'edit-item-amount',
      content: {
        title: 'Bill amount',
        body:
          'Enter the amount of money paid each time this bill is due. If it is not exactly the same each time, estimate the average amount. For bills, it is safer to estimate on the high side.',
      },
      formControlPath: 'amount',
    },
    ['item-name'],
  )
  .step(
    'item-interval',
    {
      beacon: 'edit-item-interval',
      content: {
        title: 'Bill frequency',
        body: 'Select how often this bill is due.',
      },
      formControlPath: ['interval', 'value'],
    },
    ['item-amount'],
  )
  .step(
    'item-next-due',
    {
      beacon: 'edit-item-next-due',
      content: {
        title: 'Next due date',
        body: 'Select the next date that this bill is due.',
      },
      formControlPath: ['interval', 'nextDueOn'],
    },
    ['item-interval'],
  )
  .step('item-form-valid', {
    beacon: 'edit-item-save',
    formControlPath: '.',
  })
  .step(
    'item-submitted',
    {
      beacon: 'edit-item-save',
      content: {
        title: 'Looks good!',
        body: 'Click or tap the "Save" button to add your bill.',
      },
      button: ButtonStepType.beacon,
    },
    ['item-form-valid', 'item-next-due'],
  )
  .step(
    'item-saved',
    {
      actionType: CreateBudgetItem,
      actionFilter: (action: CreateBudgetItem) => action.itemData.amount < 0,
      forceChapterComplete: true,
    },
    ['item-submitted'],
  )

const walkthroughIncome = walkThroughBills
  .chapter(
    'add-income',
    {
      name: 'Adding Income',
      component: ChapterComponent,
      beacon: 'edit-item',
      layout: {
        xs: {
          boundedBy: {
            x: GuideElementType.chapterBeacon,
            y: GuideElementType.beacon,
          },
          position: RelativePosition.top,
        },
        lg: {
          boundedBy: GuideElementType.chapterBeacon,
          position: RelativePosition.right,
        },
      },
    },
    ['add-bills'],
  )
  .step(
    'navigate',
    NavigateToNewItemStepCompletion.step('income', {
      content: {
        conditions: [ON_BUDGET_ROUTE],
        title: 'Add your income',
        layout: {
          xs: {
            boundedBy: GuideElementType.routeComponent,
            positionBoundedBy: {
              x: GuideElementType.beaconParent,
              y: GuideElementType.beacon,
            },
            position: RelativePosition.bottom,
          },
          lg: {
            positionBoundedBy: GuideElementType.routeComponent,
            position: RelativePosition.top,
          },
        },
      },
    }),
  )
  .step(
    'item-mode',
    {
      beacon: 'edit-item-mode',
      formControlPath: 'itemMode',
      requireValue: BudgetItemMode.income,
      requireVisit: true,
      content: {
        title: 'Item mode',
        body: 'Click or tap to change the item mode to "Income".',
      },
    },
    ['navigate'],
  )
  .step(
    'item-name',
    {
      beacon: 'edit-item-name',
      formControlPath: 'name',
      content: {
        title: 'Income source name',
        body: 'Enter a short name to help you identify the income source.',
      },
    },
    ['item-mode'],
  )
  .step(
    'item-amount',
    {
      beacon: 'edit-item-amount',
      formControlPath: 'amount',
      content: {
        title: 'Income amount',
        body:
          'Enter the amount of money paid for each pay day. If it is not exactly the same each time, estimate the average amount. For income, it is safer to estimate on the low side.',
      },
    },
    ['item-name'],
  )
  .step(
    'item-interval',
    {
      beacon: 'edit-item-interval',
      content: {
        title: 'Income frequency',
        body: 'Select how often this income is paid.',
      },
      formControlPath: ['interval', 'value'],
    },
    ['item-amount'],
  )
  .step(
    'item-next-due',
    {
      beacon: 'edit-item-next-due',
      content: {
        title: 'Next pay day',
        body: 'Select the date of the next pay day for this income.',
      },
      formControlPath: ['interval', 'nextDueOn'],
    },
    ['item-interval'],
  )
  .step('item-form-valid', {
    beacon: 'edit-item-save',
    formControlPath: '.',
  })
  .step(
    'item-submitted',
    {
      beacon: 'edit-item-save',
      content: {
        title: 'Looks good!',
        body: 'Click or tap the "Save" button to add your income.',
      },
      button: ButtonStepType.beacon,
    },
    ['item-form-valid', 'item-next-due'],
  )
  .step(
    'item-saved',
    {
      actionType: CreateBudgetItem,
      actionFilter: (action: CreateBudgetItem) => action.itemData.amount > 0,
      forceChapterComplete: true,
    },
    ['item-submitted'],
  )

const walkthroughPlan = walkthroughIncome
  .chapter(
    'view-plan',
    {
      name: 'Your Plan',
      beacon: 'guide',
      component: ChapterComponent,
      layout: {
        xs: {
          boundedBy: GuideElementType.beacon,
          position: RelativePosition.bottom,
        },
        lg: {
          boundedBy: GuideElementType.chapterBeacon,
          position: RelativePosition.right,
        },
      },
    },
    ['add-bills', 'add-income'],
  )
  .step('unblock-plan-enter-bills', {
    beacon: 'add-item',
    conditions: [
      {
        routePath: ['budget', String],
      },
    ],
    service: {
      type: BudgetItemsService,
      prop: 'totalsChange$',
      conditionFn: (totals) => {
        console.log('[StoryWalkthrough] unblock-plan-enter-bills', totals, Math.abs(totals.bills) > 0)
        return Math.abs(totals.bills) > 0
      },
    },
    content: {
      title: 'Add more bills',
      body: 'In order for Figure My Bills to calculate your plan, you must have some bills.',
      layout: {
        xs: {
          boundedBy: GuideElementType.beacon,
          position: RelativePosition.bottom,
        },
        lg: {
          positionBoundedBy: GuideElementType.beacon,
          position: RelativePosition.left,
          sizeModifier: 0.35,
        },
      },
    },
  })
  .step(
    'unblock-plan-add-income',
    {
      beacon: 'add-item',
      service: {
        type: BudgetItemsService,
        prop: 'totalsChange$',
        conditionFn: (totals) => totals.income > Math.abs(totals.bills),
      },
      content: {
        title: 'Add more income',
        body:
          'In order for Figure My Bills to calculate your plan, your total yearly income must be more than your total yearly bills.',
        layout: {
          xs: {
            boundedBy: {
              x: GuideElementType.chapterBeacon,
              y: GuideElementType.beacon,
            },
            positionBoundedBy: GuideElementType.beacon,
            position: RelativePosition.bottom,
          },
          lg: {
            boundedBy: GuideElementType.routeComponent,
            positionBoundedBy: GuideElementType.beacon,
            position: RelativePosition.left,
            sizeModifier: 0.35,
          },
        },
      },
    },
    ['unblock-plan-enter-bills'],
  )
  .step('navigate-to-plan', {
    beacon: 'nav-plan',
    routePath: ['budget', String, 'plan'],
    conditions: [
      serviceCondition(
        BudgetItemsService,
        'totalsChange$',
        (totals) => Math.abs(totals.bills) > 0 && totals.income > Math.abs(totals.bills),
      ),
    ],
    content: {
      title: 'Open your plan',
      layout: {
        xs: {
          boundedBy: {
            x: GuideElementType.chapterBeacon,
            y: GuideElementType.beacon,
          },
          position: RelativePosition.top,
        },
        lg: {
          boundedBy: GuideElementType.beaconParent,
          position: RelativePosition.bottom,
        },
      },
    },
  })
  .step(
    'plan-min-balance',
    {
      beacon: 'plan-min-balance',
      button: ButtonStepType.step,
      content: {
        title: 'Minimum balance',
        body:
          "Based on the information you've entered, this is the minimum amount you should have set aside in your bank account" +
          ' in order to cover your upcoming bills.',
        layout: {
          xs: {
            boundedBy: {
              x: GuideElementType.chapterBeacon,
              y: GuideElementType.beacon,
            },
            position: RelativePosition.bottom,
          },
          lg: {
            sizeBoundedBy: GuideElementType.beaconParent,
          },
        },
      },
    },
    ['navigate-to-plan'],
  )
  .step(
    'plan-income',
    {
      beacon: 'plan-income',
      button: ButtonStepType.step,
      content: {
        title: 'Income contributions',
        body:
          'Whenever your income is deposited to your bank, this amount should be set aside in order to pay your bills.',
        layout: {
          xs: {
            boundedBy: {
              x: GuideElementType.chapterBeacon,
              y: GuideElementType.beacon,
            },
            position: RelativePosition.bottom,
          },
          lg: {
            position: RelativePosition.right,
            positionBoundedBy: GuideElementType.beacon,
            sizeBoundedBy: GuideElementType.routeComponent,
            sizeModifier: 0.33,
          },
        },
      },
    },
    ['plan-min-balance'],
  )
  .step(
    'plan-min-balance-details',
    {
      beacon: 'plan-min-balance-details',
      button: ButtonStepType.step,
      content: {
        title: 'Minimum balance details',
        body: 'This is a list of upcoming bills that the "minimum balance" is needed to cover.',
        layout: {
          xs: {
            boundedBy: {
              x: GuideElementType.chapterBeacon,
              y: GuideElementType.beacon,
            },
            position: RelativePosition.top,
          },
          lg: {
            positionBoundedBy: GuideElementType.beacon,
            position: RelativePosition.right,
            sizeBoundedBy: GuideElementType.routeComponent,
          },
        },
      },
    },
    ['plan-income'],
  )
  .step(
    'plan-update',
    {
      beacon: 'nav-plan',
      button: ButtonStepType.step,
      content: {
        title: 'Check your plan after making changes',
        body:
          'Figure My Bills will automatically recalculate your plan when you add, remove, or change your income or bills.',
        layout: {
          xs: {
            boundedBy: {
              x: GuideElementType.beaconParent,
              y: GuideElementType.beacon,
            },
            position: RelativePosition.top,
          },
          lg: {
            positionBoundedBy: GuideElementType.beacon,
            position: RelativePosition.bottom,
            sizeModifier: 0.35,
          },
        },
      },
      forceChapterComplete: true,
    },
    ['plan-min-balance-details'],
  )

const walkthroughOther = walkthroughPlan
  .chapter(
    'other',
    {
      name: 'Account, Support, Legal',
      beacon: 'guide',
      component: ChapterComponent,
      layout: {
        xs: {
          boundedBy: {
            y: GuideElementType.beacon,
            x: GuideElementType.beaconParent,
          },
          position: RelativePosition.bottom,
        },
        lg: {
          position: RelativePosition.left,
        },
      },
    },
    ['view-plan'],
  )
  .step(
    'account',
    NavigateToAccountStepCompletion.step({
      beacon: 'nav-account',
      content: {
        title: 'Account',
        body: 'Sign out or manage your data.',
        layout: {
          xs: {
            position: RelativePosition.top,
            positionBoundedBy: GuideElementType.beacon,
          },
          lg: {
            position: RelativePosition.bottom,
          },
        },
      },
    }),
  )
  .step(
    'support',
    {
      beacon: 'support',
      button: ButtonStepType.step,
      content: {
        title: 'Support',
        body: 'See help documentation, revisit this guide, or contact support.',
        layout: {
          xs: {
            boundedBy: {
              y: GuideElementType.beacon,
              x: GuideElementType.beaconParent,
            },
            position: RelativePosition.bottom,
          },
          lg: {
            position: RelativePosition.left,
          },
        },
      },
    },
    ['account'],
  )
  .step(
    'terms',
    {
      beacon: 'terms',
      button: ButtonStepType.step,
      content: {
        title: 'Terms and Conditions',
        body: 'Read about the terms of using the Figure My Bills service.',
      },
    },
    ['support'],
  )
  .step(
    'privacy',
    {
      beacon: 'privacy',
      button: ButtonStepType.step,
      content: {
        title: 'Privacy Policy',
        body: 'Read about how Figure My Bills uses, and will not use your data.',
      },
    },
    ['terms'],
  )

const walkthroughDone = walkthroughOther
  .chapter(
    'done',
    {
      name: 'Summary',
      unskippable: true,
      component: WalkthroughDoneComponent,
      beacon: 'guide',
    },
    ['other'],
  )
  .step('done', {
    beacon: 'done',
    button: ButtonStepType.beacon,
  })

export const WALKTHROUGH_STORY = walkthroughDone
