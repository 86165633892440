import { Relation, Relations } from '@dandi/hal'

import { BudgetResource } from '../budget/budget.model'
import { BudgetItemResource } from './budget-item.model'

@Relations(BudgetItemResource)
export class BudgetItemRelations implements Partial<BudgetItemResource> {
  public static readonly resourceKey = 'BudgetItemRelations'

  @Relation(BudgetResource)
  public budget: BudgetResource
}
