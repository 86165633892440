import { Component } from '@angular/core'

import { AuthProviderInfo, AuthService, FirebaseAuthService } from '../shared/auth'

const SELECTOR = 'auth-handler'

enum AuthAction {
  signIn = 'Sign in',
  signUp = 'Sign up',
}

@Component({
  selector: SELECTOR,
  templateUrl: 'auth-handler.component.pug',
  styleUrls: ['auth-handler.component.scss'],
})
export class AuthHandlerComponent {
  public get providers(): AuthProviderInfo[] {
    return this.firebaseAuthService.providers
  }

  private readonly actions: AuthAction[] = [AuthAction.signIn, AuthAction.signUp]

  public collapse = true

  public get action(): AuthAction {
    return this.actions[0]
  }
  public get otherAction(): AuthAction {
    return this.actions[1]
  }
  public readonly AuthAction = AuthAction

  constructor(private authService: AuthService, private firebaseAuthService: FirebaseAuthService) {}

  public signIn(provider: AuthProviderInfo): void {
    this.authService.signIn(provider.create())
  }

  public toggleAction(): void {
    this.actions.reverse()
  }
}
