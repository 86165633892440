import { Me } from '@fmb/models'
import { Store } from '@ngxs/store'
import { Observable } from 'rxjs'
import { map, shareReplay } from 'rxjs/operators'

import { GuideUserKey } from '../guide'

export function guideUserKeyFactory(store: Store): Observable<string> {
  return store
    .select((state) => state?.me)
    .pipe(
      map((me: Me) => me?.userId?.toString()),
      shareReplay(1),
    )
}

export const guideUserKeyProvider = {
  provide: GuideUserKey,
  useFactory: guideUserKeyFactory,
  deps: [Store],
}
