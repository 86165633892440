import { Component } from '@angular/core'
import { Observable } from 'rxjs'

import { GuideService } from './guide.service'

@Component({
  selector: 'guide',
  templateUrl: './guide.component.pug',
  styleUrls: ['./guide.component.scss'],
})
export class GuideComponent {
  public readonly run$: Observable<void>

  constructor(private readonly guide: GuideService) {
    this.run$ = guide.run$
  }
}
