import { ChapterState } from '../model'

import { ChapterAction } from './guide-action'

export abstract class ChapterStateAction extends ChapterAction {
  public readonly state: Partial<ChapterState>

  protected constructor(
    defaultState: Partial<ChapterState>,
    userKey: string,
    storyId: string,
    chapterId: string,
    state?: Partial<ChapterState>,
  ) {
    super(userKey, storyId, chapterId)

    this.state = this.getState(defaultState, state)
  }

  protected getState(defaultState: Partial<ChapterState>, state: Partial<ChapterState>): Partial<ChapterState> {
    return Object.assign({}, defaultState, state)
  }
}
