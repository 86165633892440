import { Inject, Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { distinctUntilChanged, map, shareReplay, startWith, tap } from 'rxjs/operators'

import { sustained } from '../guide/rxjs'

import {
  Breakpoint,
  BreakpointOrder,
  Breakpoints,
  isPartialResponsive,
  orderedResponsive,
  PartialResponsive,
  Responsive,
  ResponsiveInputs,
} from './responsive'
import { ResponsiveConfig } from './responsive-config'
import { ResponsiveWindow } from './responsive-window'

@Injectable({ providedIn: 'root' })
export class ResponsiveService {
  public readonly breakpoint$: Observable<Breakpoint> = this.window.resize$.pipe(
    startWith(this.window.screen),
    map((screen) => this.getBreakpoint(screen.availWidth)),
    distinctUntilChanged(),
    tap((breakpoint) => console.log('[ResponsiveService] breakpoint$', Breakpoint[breakpoint])),
    shareReplay(1),
  )

  constructor(
    @Inject(ResponsiveConfig) private readonly config: ResponsiveConfig,
    @Inject(ResponsiveWindow) private readonly window: ResponsiveWindow,
  ) {}

  /**
   * Gets the active breakpoint given the specified width
   * @param width
   */
  public getBreakpoint(width: number): Breakpoint {
    const breakpoints = orderedResponsive(this.config.breakpoints)
    return breakpoints.reduce((result, [breakpoint, value]) => (width >= value ? breakpoint : result), Breakpoint.xs)
  }

  /**
   *
   * @param breakpoint
   */
  public is(breakpoint: Breakpoint): Observable<boolean> {
    return this.breakpoint$.pipe(
      map((brk) => brk === breakpoint),
      distinctUntilChanged(),
    )
  }

  public isAtLeast(breakpoint: Breakpoint): Observable<boolean> {
    return this.breakpoint$.pipe(
      map((brk) => BreakpointOrder[brk] >= BreakpointOrder[breakpoint]),
      distinctUntilChanged(),
    )
  }

  public isAtMost(breakpoint: Breakpoint): Observable<boolean> {
    return this.breakpoint$.pipe(
      map((brk) => BreakpointOrder[brk] <= BreakpointOrder[breakpoint]),
      distinctUntilChanged(),
    )
  }

  public for<T>(...inputs: ResponsiveInputs<T>): Observable<T> {
    const [input] = inputs
    if (typeof input !== 'undefined' && !isPartialResponsive<T>(input)) {
      return sustained(input)
    }
    return this.breakpoint$.pipe(
      map((breakpoint) => this.forBreakpoint(breakpoint, ...inputs)),
      distinctUntilChanged(),
    )
  }

  public forBreakpoint<T>(breakpoint: Breakpoint, ...inputs: ResponsiveInputs<T>): T {
    const [input] = inputs
    if (typeof input !== 'undefined' && !isPartialResponsive(input)) {
      return input
    }
    const responsiveInputs = this.mergeResponsive(...inputs)
    const order = BreakpointOrder[breakpoint]
    return Breakpoints.reduce((result, brk: Breakpoint, brkOrder: number) => {
      if (brkOrder > order) {
        return result
      }
      const brkResult = responsiveInputs[brk]
      if (typeof brkResult === 'undefined') {
        return result
      }
      if (typeof brkResult === 'object') {
        return Object.assign({}, result, brkResult)
      }
      return brkResult
    }, responsiveInputs.xs)
  }

  public makeResponsive<T>(...inputs: (T | PartialResponsive<T>)[]): PartialResponsive<T>[] {
    return inputs
      .filter((input) => typeof input !== 'undefined')
      .map((input) => (isPartialResponsive(input) ? input : { xs: input }))
  }

  public mergeResponsive<T>(...inputs: (T | PartialResponsive<T>)[]): Responsive<T> {
    const responsiveInputs = this.makeResponsive(...inputs).reverse()
    return Object.assign({}, ...responsiveInputs)
  }
}
