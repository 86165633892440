import { Uuid } from '@dandi/common'
import { HalModelBase, ResourceId } from '@dandi/hal'
import { Property, Required } from '@dandi/model'

import { DateTime } from 'luxon'

import { BudgetItemResource } from '../budget-item'
import { BudgetPermission } from '../budget-permission'
import { CreatedData } from '../created-data'
import { ModifiedData } from '../modified-data'

export interface BudgetCreationModel {
  name: string
}

export interface BudgetModel extends BudgetCreationModel, CreatedData, ModifiedData {
  budgetId: string | Uuid
  name: string
  hiddenOn: number | DateTime
  hiddenBy: string | Uuid
  permissions: BudgetPermission
}

export class BudgetCreationRequest extends HalModelBase implements BudgetCreationModel {
  public static readonly resourceKey: string = 'BudgetCreationRequest'

  constructor(source?: BudgetCreationModel) {
    super(source)
  }

  @Property(String)
  @Required()
  public name: string
}

export class BudgetResource extends BudgetCreationRequest implements BudgetModel {
  public static readonly resourceKey = 'BudgetResource'

  constructor(source?: BudgetModel) {
    super(source)
  }

  @Property(Uuid)
  @Required()
  @ResourceId()
  public budgetId: Uuid

  @Property(DateTime)
  @Required()
  public createdOn: DateTime

  @Property(Uuid)
  @Required()
  public createdBy: Uuid

  @Property(DateTime)
  @Required()
  public modifiedOn: DateTime

  @Property(Uuid)
  @Required()
  public modifiedBy: Uuid

  @Property(DateTime)
  public hiddenOn: DateTime

  @Property(Uuid)
  public hiddenBy: Uuid

  @Property(Number)
  public permissions: BudgetPermission

  public get items(): BudgetItemResource[] {
    return this.getEmbedded<BudgetItemResource[]>('items')
  }
}
