import { ChangeDetectionStrategy, Component, Inject, ViewChild } from '@angular/core'

import { GuideModalComponent } from '../guide/guide-modal.component'
import { Chapter, Story } from '../guide'

@Component({
  selector: 'walkthrough-how-this-works',
  templateUrl: './how-this-works.component.pug',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HowThisWorksComponent {
  @ViewChild(GuideModalComponent)
  public modal: GuideModalComponent

  constructor(@Inject(Story) public readonly story: Story, @Inject(Chapter) public readonly chapter: Chapter) {}
}
