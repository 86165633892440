import { BudgetItemResource } from '@fmb/models'

import { AnalyticsAction } from '../shared/analytics'

export class UpdateBudgetItem extends AnalyticsAction {
  public static type = '[BudgetItem] Update'

  constructor(public readonly item: BudgetItemResource) {
    super({ category: 'BudgetItem', action: 'Update' })
  }
}
