import { Action, State, StateContext } from '@ngxs/store'

import {
  ChapterStateAction,
  CompleteChapter,
  ResetChapterState,
  ResetGuideState,
  ResetStoryState,
  StartChapter,
} from './action'
import { ChapterState, GuideStateModel, StoryState } from './model'

@State<GuideStateModel>({
  name: 'guide',
  defaults: {},
})
export class GuideState {
  @Action([CompleteChapter, StartChapter])
  public onChapterStateAction(context: StateContext<GuideStateModel>, action: ChapterStateAction): GuideStateModel {
    const state = context.getState()
    const userState = (state && state[action.userKey]) || { stories: {} }
    const stories = userState.stories
    const storyState: StoryState = stories[action.storyId] || { chapters: {} }
    const chapterState: ChapterState = storyState.chapters[action.chapterId] || {}

    return context.patchState({
      [action.userKey]: {
        stories: {
          ...stories,
          [action.storyId]: {
            chapters: {
              ...(storyState.chapters || {}),
              [action.chapterId]: {
                ...(chapterState || {}),
                ...(action.state || {}),
              },
            },
          },
        },
      },
    })
  }

  @Action(ResetGuideState)
  public onResetGuideState(context: StateContext<GuideStateModel>, { userKey }: ResetGuideState): GuideStateModel {
    const state = context.getState()
    delete state[userKey]
    return context.patchState({
      [userKey]: { stories: {} },
    })
  }

  @Action(ResetStoryState)
  public onResetStoryState(
    context: StateContext<GuideStateModel>,
    { userKey, storyId }: ResetStoryState,
  ): GuideStateModel {
    const state = context.getState()
    const userState = state[userKey]
    if (!userState) {
      return state
    }
    return context.patchState({
      [userKey]: { stories: { ...userState.stories }, [storyId]: {} },
    })
  }

  @Action(ResetChapterState)
  public onResetChapterState(
    context: StateContext<GuideStateModel>,
    { userKey, storyId, chapterId }: ResetChapterState,
  ): GuideStateModel {
    const state = context.getState()
    const userState = state[userKey]
    if (!userState) {
      console.warn('No guide state for user', { userKey })
      return state
    }
    const storyState: StoryState = userState.stories[storyId]
    if (!storyState) {
      console.warn('No story state for user', { userKey, storyId })
      return state
    }
    if (!storyState.chapters[chapterId]) {
      console.warn('No chapter state for user', { userKey, storyId, chapterId }, storyState)
    }
    delete storyState.chapters[chapterId]

    return context.patchState({
      [userKey]: {
        stories: {
          ...userState.stories,
          [storyId]: {
            chapters: {
              ...userState.stories[storyId].chapters,
              [chapterId]: {},
            },
          },
        },
      },
    })
  }
}
