import { ServiceStreams } from './condition'
import { AnyStep, AnyStepData, Chapter, Chapters, GuideEntity, SkippableGuideEntity, Steps } from './model'
import { InjectionToken } from '@angular/core'

// eslint-disable-next-line @typescript-eslint/ban-types
type Empty = {}

export interface StoryStatic {
  define(id: string, story: Omit<Story, 'id' | 'chapters'>): StoryFactory<Empty>
}

export interface Story<TChapters extends Chapters = Chapters> extends GuideEntity, SkippableGuideEntity {
  chapters: TChapters
}

export type FactoryResult<TObj, TId extends string, TValue> = {
  [TChapterId in keyof TObj | TId]: TValue
}

export type StoryFactoryResult<TChapters extends Chapters, TId extends string> = FactoryResult<TChapters, TId, Chapter>
export type StoryChapterFactoryResult<TSteps extends Steps, TId extends string> = FactoryResult<TSteps, TId, AnyStep>

export interface StoryFactory<TChapters extends Chapters> {
  chapter<TId extends string, TChapter extends Chapter>(
    id: TId,
    chapter: Omit<TChapter, 'id'>,
    dependencies?: (keyof TChapters)[],
  ): StoryChapterFactory<StoryFactoryResult<TChapters, TId>, Empty>
}

export interface StoryChapterFactory<TChapters extends Chapters, TSteps extends Steps> extends StoryFactory<TChapters> {
  step<TId extends string, TService = unknown, TProp extends keyof ServiceStreams<TService> = never>(
    id: TId,
    step: AnyStepData<TService, TProp>,
    dependencies?: (keyof TSteps)[],
  ): StoryChapterFactory<TChapters, StoryChapterFactoryResult<TSteps, TId>>
}

export class StoryFactoryImpl<TChapters extends Chapters, TSteps extends Steps = never>
  implements Story<TChapters>, StoryFactory<TChapters>, StoryChapterFactory<TChapters, TSteps> {
  public static define(id: string, story: Omit<Story, 'id' | 'chapters'>): StoryFactory<Empty> {
    return new StoryFactoryImpl<Empty>(Object.assign({}, story, { id, chapters: {} }))
  }

  private static record<TId extends string, TValue, TMap extends { [key: string]: TValue }>(
    id: TId,
    value: Omit<TValue, 'id'>,
    dependencies: (keyof TMap)[],
  ): Record<TId, TValue> {
    return { [id]: (Object.assign({ id, dependencies }, value) as unknown) as TValue } as Record<TId, TValue>
  }

  public readonly id: string
  public readonly chapters: TChapters
  public readonly name: string

  private readonly lastChapter: Chapter<TSteps>

  protected constructor(story: Story<TChapters>, newChapter?: Record<string, Chapter<TSteps>>) {
    Object.assign(this, story)
    this.chapters = Object.assign({}, story.chapters, newChapter)
    if (newChapter) {
      this.lastChapter = newChapter[Object.keys(newChapter)[0]]
    }
  }

  public chapter<TId extends string>(
    id: TId,
    chapter: Omit<Chapter, 'id'>,
    dependencies?: (keyof TChapters)[],
  ): StoryChapterFactory<StoryFactoryResult<TChapters, TId>, Empty> {
    const chapterRecord = StoryFactoryImpl.record(id, chapter, dependencies)
    return new StoryFactoryImpl<StoryFactoryResult<TChapters, TId>, Empty>(this, chapterRecord)
  }

  public step<TId extends string>(
    id: TId,
    step: Omit<AnyStep, 'id'>,
    dependencies?: (keyof TSteps)[],
  ): StoryChapterFactory<TChapters, StoryChapterFactoryResult<TSteps, TId>> {
    const stepRecord = StoryFactoryImpl.record(id, step, dependencies)
    const steps = Object.assign({}, this.lastChapter.steps, stepRecord)
    const chapter = Object.assign({}, this.lastChapter, { steps })
    const chapterRecord = { [chapter.id]: chapter }
    return new StoryFactoryImpl<TChapters, StoryChapterFactoryResult<TSteps, TId>>(this, chapterRecord)
  }
}

export const Story: StoryStatic = StoryFactoryImpl
export const Stories = new InjectionToken<Story[]>('Stories')
