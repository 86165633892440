import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'

import { GuideModule, Stories } from '../guide'
import { ModalModule } from '../shared/modal'

import { HowThisWorksComponent } from './how-this-works.component'
import { WALKTHROUGH_STORY } from './story-walkthrough'
import { WalkthroughDoneComponent } from './walkthrough-done.component'

@NgModule({
  declarations: [HowThisWorksComponent, WalkthroughDoneComponent],
  entryComponents: [HowThisWorksComponent, WalkthroughDoneComponent],
  imports: [CommonModule, GuideModule, ModalModule, RouterModule],
  providers: [
    {
      provide: Stories,
      multi: true,
      useValue: WALKTHROUGH_STORY,
    },
  ],
})
export class WalkthroughStoryModule {}
