import { Me } from '@fmb/models'
import { Action, State, StateContext } from '@ngxs/store'
import { Observable } from 'rxjs'
import { switchMap, switchMapTo, tap } from 'rxjs/operators'

import { LoadBudgets, LoadMe, RemoveMe, SignOut } from '../action'
import { ResetGuideState } from '../guide/action'
import { AuthService } from '../shared/auth'

import { MeEndpoint } from './me.endpoint'

@State<Me>({
  name: 'me',
  defaults: undefined,
})
export class MeState {
  constructor(private meEndpoint: MeEndpoint, private authService: AuthService) {}

  @Action(LoadMe)
  public loadMe(context: StateContext<Me>): Observable<void> {
    return this.meEndpoint.fetch().pipe(
      tap((me) => context.setState(me)),
      switchMap((me) => context.dispatch(new LoadBudgets(me))),
    )
  }

  @Action(RemoveMe)
  public removeMe(context: StateContext<Me>): Observable<void> {
    const state = context.getState()
    return this.meEndpoint
      .delete()
      .pipe(switchMapTo(context.dispatch([new SignOut(), new ResetGuideState(state.userId.toString())])))
  }

  @Action(SignOut)
  public signOut(context: StateContext<Me>): Observable<boolean> {
    context.setState(undefined)
    return this.authService.signOut()
  }
}
