import { environment as BASE_ENV } from './environment.base'

export const environment = Object.assign({}, BASE_ENV, {
  name: 'stage',
})

export const APP_CONFIG = {
  api: {
    host: 'https://api.stage.figuremybills.com',
  },
  firebase: {
    apiKey: 'AIzaSyAQ0hjQK4TX7B5cGqyybuboCUs5a5qFAZM',
    authDomain: 'auth.stage.figuremybills.com',
    databaseURL: 'https://fmb-stage.firebaseio.com',
    projectId: 'fmb-stage',
    storageBucket: 'fmb-stage.appspot.com',
    messagingSenderId: '125021912184',
  },
}
