export class DefaultMap<TKey, TValue> extends Map<TKey, TValue> {
  public get(key: TKey, valueFn?: () => TValue): TValue {
    let value = super.get(key)
    if (!value && typeof valueFn === 'function') {
      value = valueFn()
      this.set(key, value)
    }
    return value
  }
}
