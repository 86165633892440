import { Injectable } from '@angular/core'
import { Uuid } from '@dandi/common'
import { BudgetItemResource, BudgetItemCreationModel, BudgetResource, isExistingItem } from '@fmb/models'
import { Store } from '@ngxs/store'
import { combineLatest, Observable } from 'rxjs'
import { distinctUntilChanged, filter, map, shareReplay, throttle } from 'rxjs/operators'

import { CreateBudgetItem, DeleteBudgetItem, LoadSimulation, UpdateBudgetItem } from '../action'
import { RouteHelper } from '../shared/app-common'

import { BudgetService } from './budget.service'
import { BudgetState, BudgetStateModel } from './budget.state'
import { YearlyTotalsChangeEvent } from './yearly-totals-change-event'

@Injectable({ providedIn: 'root' })
export class BudgetItemsService {
  public readonly totalsChange$: Observable<YearlyTotalsChangeEvent>
  public readonly budgetItems$: Observable<BudgetItemResource[]>

  private readonly state$: Observable<BudgetStateModel>

  public constructor(private routeHelper: RouteHelper, private store: Store, private budget: BudgetService) {
    this.state$ = store.select<BudgetStateModel>(BudgetState).pipe(
      filter((state) => !!(state && state.budgetItems)),
      shareReplay(1),
    )
    this.budgetItems$ = this.budgetItems(this.budget.currentBudgetId$)
    this.totalsChange$ = this.budgetItems$.pipe(
      map((items) =>
        items?.reduce(
          (result, item) => {
            if (item.amount < 0) {
              result.bills += item.yearlyAmount
            } else {
              result.income += item.yearlyAmount
            }
            return result
          },
          { bills: 0, income: 0 },
        ),
      ),
      shareReplay(1),
    )

    // TODO: move to BudgetState, check from createBudgetItem / updateBudgetItem
    this.totalsChange$
      .pipe(
        filter((event) => event && event.bills !== 0 && event.income !== 0),
        distinctUntilChanged((a, b) => a.bills === b.bills && a.income === b.income),
        throttle(() => this.store.dispatch(new LoadSimulation())),
      )
      .subscribe()
  }

  public budgetItems(budgetId$: Observable<string | Uuid>): Observable<BudgetItemResource[]> {
    return combineLatest([this.state$, budgetId$]).pipe(
      map(([state, budgetId]) => state.budgetItems[budgetId.toString()]),
      shareReplay(1),
    )
  }

  public saveItem(budget: BudgetResource, item: BudgetItemCreationModel): Observable<void> {
    if (isExistingItem(item)) {
      return this.store.dispatch(new UpdateBudgetItem(item))
    }
    return this.store.dispatch(new CreateBudgetItem(budget, item))
  }

  public deleteItem(item: BudgetItemResource): Observable<void> {
    return this.store.dispatch(new DeleteBudgetItem(item))
  }
}
