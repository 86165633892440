import {
  Directive,
  ElementRef,
  forwardRef,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  ViewContainerRef,
} from '@angular/core'
import { FormControlName } from '@angular/forms'
import { combineLatest, defer, NEVER, Observable, Subject, Subscription } from 'rxjs'
import { filter, finalize, map, mapTo, switchMap, take, takeUntil } from 'rxjs/operators'

import { Memoized } from '../shared/app-common'

import { ChapterStepComponent } from './chapter-step.component'
import { ComponentRefFactory } from './component-ref-factory'
import { ConditionService } from './condition/completion-state'
import { GuideBeacon } from './guide-beacon'
import { GuideBeaconsService } from './guide-beacons.service'
import { BeaconStep, ButtonStep, ButtonStepType, Chapter, isContentStep, Step } from './model'
import { ObservableMap, sustained } from './rxjs'
import { Story } from './story'

interface Registered<T> {
  story: Story
  chapter: Chapter
  step: Step
  component: T
}

// noinspection AngularMissingOrInvalidDeclarationInModule
@Directive({
  selector: '[guideBeacon]',
  providers: [
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    { provide: GuideBeacon, useExisting: forwardRef(() => GuideBeaconDirective) },
  ],
})
export class GuideBeaconDirective implements GuideBeacon, OnInit, OnDestroy {
  @Input('guideBeacon')
  public name: string

  public element: HTMLElement

  public get visible(): boolean {
    // Note: this will incorrectly return false if the element uses fixed positioning
    //       see https://stackoverflow.com/questions/19669786/check-if-element-is-visible-in-dom
    return (
      !!this.element.offsetParent ||
      (this.computedStyles.position === 'fixed' &&
        this.computedStyles.display !== 'none' &&
        this.computedStyles.visibility !== 'hidden')
    )
  }

  @HostBinding('class.guide-beacon--active')
  private guideBeaconActive: boolean

  @HostBinding('attr.aria-describedby')
  private describedBy: string

  @Memoized()
  private get computedStyles(): CSSStyleDeclaration {
    return window.getComputedStyle(this.element)
  }

  private readonly click$$ = new Subject<void>()
  private readonly registeredSteps = new Map<string, Registered<ComponentRefFactory<ChapterStepComponent>>>()
  private readonly activatedSteps = new ObservableMap<string, Registered<ChapterStepComponent>>()
  private readonly sub = new Subscription()

  constructor(
    private readonly conditions: ConditionService,
    private readonly beacons: GuideBeaconsService,
    public readonly elementRef: ElementRef,
    public readonly viewContainerRef: ViewContainerRef,
    @Optional() private readonly formControlName: FormControlName,
  ) {
    this.element = elementRef.nativeElement
  }

  public ngOnInit(): void {
    this.beacons.register(this)
  }

  public ngOnDestroy(): void {
    const registeredSteps = [...this.registeredSteps.values()]
    registeredSteps.forEach(({ story, chapter, step }) => this.finalizeStep(story, chapter, step))
    this.beacons.unregister(this)
    this.click$$.complete()
    this.sub.unsubscribe()
  }

  public renderStep(
    story: Story,
    chapter: Chapter,
    step: BeaconStep,
    unlocked$: Observable<boolean>,
    complete$: Observable<boolean>,
  ): Observable<boolean> {
    console.log('[GuideBeaconDirective] renderStep', chapter.id, step.id)

    return defer(() => {
      const componentRefFactory = isContentStep(step)
        ? ComponentRefFactory.create(this.viewContainerRef.injector, story, chapter, step, ChapterStepComponent)
        : undefined
      this.registeredSteps.set(this.getRegistrationKey(story, chapter, step), {
        story,
        chapter,
        step,
        component: componentRefFactory,
      })
      const contentConditions$ = isContentStep(step)
        ? this.conditions.getConditionsMet(step.content.conditions)
        : sustained(true)
      return combineLatest([unlocked$, contentConditions$, complete$]).pipe(
        map(([unlocked, contentConditionsMet, stepComplete]) => {
          const activate = unlocked && contentConditionsMet && !stepComplete && this.visible
          console.log('[GuideBeaconDirective] renderStep unlocked$, complete$', chapter.id, step.id, {
            unlocked,
            contentConditionsMet,
            activate,
            stepComplete,
            visible: this.visible,
          })
          if (activate) {
            this.activateStep(story, chapter, step)
            return true
          }
          this.deactivateStep(story, chapter, step)
          return false
        }),
        takeUntil(componentRefFactory?.destroy$ || NEVER),
        finalize(() => {
          this.finalizeStep(story, chapter, step)
          componentRefFactory?.destroy()
        }),
      )
    })
  }

  public activateStep(story: Story, chapter: Chapter, step: Step): void {
    console.log('[GuideBeaconDirective] activateStep', chapter.id, step.id)

    const key = this.getRegistrationKey(story, chapter, step)
    const componentRefFactory = this.registeredSteps.get(key)?.component
    const componentRef = componentRefFactory?.attach()
    this.activatedSteps.set(key, { story, chapter, step, component: componentRef?.instance })
    this.updateGuideBeaconActive(componentRef?.instance)
  }

  public deactivateStep(story: Story, chapter: Chapter, step: Step): void {
    console.log('[GuideBeaconDirective] deactivateStep', chapter.id, step.id)

    const key = this.getRegistrationKey(story, chapter, step)
    this.activatedSteps.delete(key)
    const componentRefFactory = this.registeredSteps.get(key)?.component
    componentRefFactory?.detach()
    this.updateGuideBeaconActive()
  }

  public finalizeStep(story: Story, chapter: Chapter, step: Step): void {
    console.log('[GuideBeaconDirective] finalizeStep', chapter.id, step.id)

    const key = this.getRegistrationKey(story, chapter, step)
    this.deactivateStep(story, chapter, step)
    this.registeredSteps.delete(key)
  }

  public stepButton(story: Story, chapter: Chapter, step: ButtonStep): Observable<void> {
    const key = this.getRegistrationKey(story, chapter, step)
    if (step.button === ButtonStepType.beacon) {
      return this.click$$.pipe(
        filter(() => {
          console.log('[GuideBeaconDirective] stepButton click$$', chapter.id, step.id, this.activatedSteps.has(key))
          return this.activatedSteps.has(key)
        }),
      )
    }

    return this.activatedSteps.values$.pipe(
      map((activatedSteps) => activatedSteps.find((activatedStep) => activatedStep.step.id === step.id)),
      switchMap((activatedStep) => (activatedStep ? activatedStep.component.click$ : NEVER)),
      mapTo(undefined),
      take(1),
    )
  }

  @HostListener('click')
  private onClick(): void {
    this.click$$.next()
  }

  private getRegistrationKey(story: Story, chapter: Chapter, step: Step): string {
    return `${story.id}:::${chapter.id}:::${step.id}`
  }

  private updateGuideBeaconActive(component?: ChapterStepComponent): void {
    this.guideBeaconActive = !!this.activatedSteps.size
    if (this.guideBeaconActive) {
      this.describedBy =
        component?.elementId ||
        [...this.activatedSteps.values()].find((c) => c?.component?.elementId)?.component?.elementId
    } else {
      this.describedBy = undefined
    }
  }
}
