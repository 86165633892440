import { Inject, Injectable, StaticProvider } from '@angular/core'
import { Observable } from 'rxjs'
import { distinctUntilChanged, map, tap } from 'rxjs/operators'

import { ServiceConditionDef, ServiceConditionFn, ServiceProp, ServiceStreams, SourceService } from '../'

@Injectable()
export class ServiceConditionCompletion<TService, TProp extends keyof ServiceStreams<TService>> {
  public static providers<TService, TProp extends keyof ServiceStreams<TService>>({
    type,
    prop,
    conditionFn,
  }: ServiceConditionDef<TService, TProp>): StaticProvider[] {
    return [
      {
        provide: ServiceConditionCompletion,
        deps: [SourceService, ServiceProp, ServiceConditionFn],
      },
      {
        provide: SourceService,
        useFactory: (service) => service,
        deps: [type],
      },
      {
        provide: ServiceProp,
        useValue: prop,
      },
      {
        provide: ServiceConditionFn,
        useValue: conditionFn,
      },
    ]
  }

  public readonly complete$: Observable<boolean>

  constructor(
    @Inject(SourceService) sourceService: ServiceStreams<TService>,
    @Inject(ServiceProp) streamProp: TProp,
    @Inject(ServiceConditionFn) conditionFn: ServiceConditionFn<TService, TProp>,
  ) {
    const source$ = sourceService[streamProp]
    this.complete$ = source$.pipe(
      tap((sourceVal) =>
        console.log('[ServiceConditionCompletion] sourceVal', sourceVal, { sourceService, streamProp, conditionFn }),
      ),
      map(conditionFn),
      distinctUntilChanged(),
      tap((result) => console.log('[ServiceConditionCompletion] result', result)),
    )
  }
}
