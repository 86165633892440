import { Uuid } from '@dandi/common'

import { BudgetItemCreationModel } from './budget-item'

export enum SharingMode {
  proportional = 0,
  equal = 1,
}

export interface PlanRequest {
  items: BudgetItemCreationModel[]
  sharingMode: SharingMode
}

export interface PlanYearlyAmounts {
  yearlyTotalBills: number
  yearlyTotalDeposits: number
}

export interface Plan extends PlanYearlyAmounts {
  bills: BudgetItemCreationModel[]
  deposits: BudgetItemCreationModel[]
  depositContributions: Map<Uuid, number>
}
