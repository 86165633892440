import { ComponentFactoryResolver, Inject, Injectable, Injector } from '@angular/core'
import { Store } from '@ngxs/store'
import { forkJoin, merge, NEVER, Observable, of, race } from 'rxjs'
import {
  distinctUntilKeyChanged,
  finalize,
  map,
  mapTo,
  mergeMap,
  pluck,
  share,
  shareReplay,
  switchMap,
  switchMapTo,
  take,
  tap,
  first,
} from 'rxjs/operators'

import { RouteHelper } from '../shared/app-common'

import { CompleteChapter, GuideAction, GuideActionParameters, StartChapter } from './action'
import { ChapterRunState } from './chapter-run-state'
import { ChapterStepsManager } from './chapter-steps-manager'
import { ConditionService } from './condition/completion-state'
import { GuideBeacon } from './guide-beacon'
import { GuideBeaconsService } from './guide-beacons.service'
import { GuideUserKey } from './guide-user-key'
import { Chapter, ChapterState, GuideUserState } from './model'
import { exists, sustained } from './rxjs'
import { StepCompletionStateFactory } from './step-completion-state'
import { Stories, Story } from './story'

@Injectable({ providedIn: 'root' })
export class GuideService {
  public readonly run$: Observable<void>
  public readonly state$: Observable<GuideUserState>

  private readonly chapterStates = new Map<string, Observable<ChapterState>>()

  constructor(
    @Inject(GuideUserKey) private readonly guideUserKey$: Observable<string>,
    private readonly guideBeacons: GuideBeaconsService,
    @Inject(Stories) private readonly stories: Story[],
    private readonly store: Store,
    private readonly cfr: ComponentFactoryResolver,
    private readonly injector: Injector,
    router: RouteHelper,
  ) {
    console.log('[GuideService] ctr', stories)

    this.state$ = guideUserKey$.pipe(
      switchMap((userKey) => {
        if (!userKey) {
          return NEVER
        }
        return store.select<GuideUserState>((state) => state.guide[userKey])
      }),
    )
    this.run$ = router.routeData$.pipe(
      first(),
      switchMap(() => merge(...stories.map((story) => this.runStory(story)))),
      share(),
      finalize(() => console.log('[GuideService] run$ finalize')),
    )
  }

  public dispatch<TAction extends GuideAction, TActionType extends new (userKey: string, ...args: any[]) => TAction>(
    actionType: TActionType,
    ...args: GuideActionParameters<TActionType>
  ): Observable<any> {
    return this.guideUserKey$.pipe(
      take(1),
      switchMap((userKey) => this.store.dispatch(new actionType(userKey, ...args))),
    )
  }

  private chapterState(story: Story, chapterId: string): Observable<ChapterState> {
    const key = `${story.id}:::${chapterId}`
    const chapterState$ =
      this.chapterStates.get(key) ||
      this.state$.pipe(
        pluck('stories', story.id, 'chapters', chapterId),
        map((state: ChapterState) => state || {}),
        shareReplay(1),
      )
    this.chapterStates.set(key, chapterState$)
    return chapterState$
  }

  private runStory(story: Story): Observable<void> {
    return forkJoin(...Object.values(story.chapters).map((chapter) => this.runChapter(story, chapter)))
  }

  private runChapter(story: Story, chapter: Chapter): Observable<void> {
    return this.initChapter(story, chapter).pipe(
      tap((state) => console.log('[GuideService] runChapter', chapter.id, state)),
      mergeMap((state) => {
        console.log('[GuideService] runChapter (in mergeMap) waiting for beacon', chapter.id, chapter.beacon)
        const render$ = state.beacon$.pipe(
          exists(),
          tap((beacon) => console.log('[GuideService] runChapter (in render$: beacon$)', chapter.id, beacon)),
          map((beacon) => ({ ...state, beacon })),
          mergeMap(this.renderChapterComponent.bind(this)),
        )
        const complete$ = state.stepsManager.complete$.pipe(
          tap(() => console.log('[GuideService] runChapter (complete$/tap)', chapter.id)),
          finalize(() => console.log('[GuideService] runChapter (complete$/finalize)', chapter.id)),
          switchMapTo(of(state)),
        )
        return race(render$, complete$)
      }),
      mergeMap(this.executeChapterComponent.bind(this)),
      // takeUntil(this.chapterComplete(story, chapter.id)),
      finalize(() => console.log('[GuideService] runChapter (finalize)', chapter.id)),
    )
  }

  private initChapter(story: Story, chapter: Chapter): Observable<ChapterRunState> {
    console.log('[GuideService] initChapter', chapter)
    return this.chapterState(story, chapter.id).pipe(
      distinctUntilKeyChanged('completedOn'),
      switchMap((state) => {
        if (state.completedOn) {
          return NEVER
        }
        return this.waitForChapterDependencies(story, chapter)
      }),
      map(() => {
        const beacon$ = this.guideBeacons.get(chapter.beacon)
        const providers = [
          {
            provide: Story,
            useValue: story,
          },
          {
            provide: Chapter,
            useValue: chapter,
          },
          {
            provide: ChapterStepsManager,
            deps: [GuideBeaconsService, ConditionService, StepCompletionStateFactory, Story, Chapter],
          },
        ]
        const injector = Injector.create({ providers, parent: this.injector })
        return { beacon$, story, chapter, stepsManager: injector.get(ChapterStepsManager) }
      }),
    )
  }

  private renderChapterComponent({
    story,
    chapter,
    beacon,
    stepsManager,
    ...state
  }: ChapterRunState): Observable<ChapterRunState> {
    console.log('[GuideService] renderChapterComponent', { ...state, story, chapter, beacon, stepsManager })
    const providers = [
      {
        provide: Story,
        useValue: story,
      },
      {
        provide: Chapter,
        useValue: chapter,
      },
      { provide: ChapterStepsManager, useValue: stepsManager },
      { provide: GuideBeacon, useValue: beacon },
    ]
    const injector = Injector.create({ providers, parent: beacon.viewContainerRef.injector })
    const componentFactory = this.cfr.resolveComponentFactory(chapter.component)
    beacon.viewContainerRef.clear()
    const componentRef = beacon.viewContainerRef.createComponent(componentFactory, undefined, injector)
    componentRef.hostView.markForCheck()
    return this.dispatch(StartChapter, story.id, chapter.id).pipe(
      switchMapTo(sustained({ ...state, story, chapter, stepsManager, beacon, componentRef })),
      shareReplay(1),
      finalize(() => {
        console.log('[GuideService] renderChapterComponent finalize')
      }),
    )
  }

  private executeChapterComponent({ story, chapter, stepsManager, componentRef }: ChapterRunState): Observable<void> {
    const stepsComplete$ = stepsManager.complete$.pipe(
      mergeMap(() => this.dispatch(CompleteChapter, story.id, chapter.id)),
    )

    const stateComplete$ = this.chapterComplete(story, chapter.id)

    return race(stepsComplete$, stateComplete$).pipe(
      finalize(() => {
        if (componentRef) {
          componentRef.destroy()
        }
      }),
    )
  }

  private waitForChapterDependencies(story: Story, chapter: Chapter): Observable<Chapter> {
    if (!chapter.dependencies?.length) {
      return of(chapter)
    }

    console.log('[GuideService] waitForChapterDependencies', chapter.id, chapter.dependencies)
    return forkJoin(chapter.dependencies.map((req) => this.chapterComplete(story, req))).pipe(mapTo(chapter))
  }

  private chapterComplete(story: Story, chapterId: string): Observable<true> {
    return this.chapterState(story, chapterId).pipe(
      distinctUntilKeyChanged('completedOn'),
      exists('completedOn'),
      take(1),
      mapTo(true),
    )
  }
}
