import { ChangeDetectorRef } from '@angular/core'
import { Observable, ReplaySubject, Subject, Subscription } from 'rxjs'
import { mapTo, mergeMap, tap } from 'rxjs/operators'

export interface ChangeDetectorHost {
  changeDetectorRef: ChangeDetectorRef
}

function isChangeDetectorHost(obj: any): obj is ChangeDetectorHost {
  return typeof obj?.changeDetectorRef === 'object' && typeof obj.changeDetectorRef.markForCheck === 'function'
}

interface BoundValue<THost, TProp extends keyof THost> {
  prop: TProp
  value$: Observable<THost[TProp]>
}

export class AsyncBinder<THost> {
  private readonly sub = new Subscription()
  private readonly stream$$ = new Subject<BoundValue<THost, keyof THost>>()
  private readonly init$$ = new ReplaySubject<void>(1)

  constructor(host: THost & ChangeDetectorHost)
  constructor(host: THost, changeDetectorRef: ChangeDetectorRef)
  constructor(
    private readonly host: THost | (THost & ChangeDetectorHost),
    private readonly changeDetectorRef?: ChangeDetectorRef,
  ) {
    this.changeDetectorRef = isChangeDetectorHost(host) ? host.changeDetectorRef : changeDetectorRef
    this.sub.add(this.getBound().subscribe())
  }

  public bind<TProp extends keyof THost>(prop: TProp, value$: Observable<THost[TProp]>): this {
    this.stream$$.next({ prop, value$ })
    return this
  }

  public unbind(): void {
    this.sub.unsubscribe()
  }

  public init(): void {
    this.init$$.next()
  }

  protected getBound(): Observable<unknown> {
    return this.stream$$.pipe(
      mergeMap((bound) => this.init$$.pipe(mapTo(bound))),
      mergeMap((bound) => bound.value$.pipe(tap((value) => (this.host[bound.prop] = value)))),
      tap(() => this.changeDetectorRef.markForCheck()),
    )
  }
}
