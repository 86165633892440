import { Uuid } from '@dandi/common'
import { HalModelBase } from '@dandi/hal'
import { Email, Property, Required } from '@dandi/model'

export enum ContactReason {
  bug = 'bug',
  error = 'error',
  other = 'other',
  request = 'request',
  usage = 'usage',
}

export class ContactRequest extends HalModelBase {
  @Required()
  @Email()
  public from: string

  @Property(String)
  @Required()
  public body: string

  @Property(String)
  @Required()
  public reason: ContactReason

  constructor(obj?: Partial<ContactRequest>) {
    super(obj)
  }
}

export class ContactRequestSuccess extends ContactRequest {
  @Required()
  @Property(Uuid)
  public requestId: Uuid

  constructor(obj?: Partial<ContactRequestSuccess>) {
    super(obj)
  }
}
