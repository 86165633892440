export function Memoized(): PropertyDecorator {
  return function memoizedDecorator(target, propertyKey): void {
    const descriptor = Object.getOwnPropertyDescriptor(target, propertyKey)
    if (!descriptor.get) {
      throw new Error(`Property ${propertyKey.toString()} does not have a get accessor`)
    }
    let value
    let valueSet = false
    const ogGet = descriptor.get
    const get = function get() {
      if (valueSet) {
        return value
      }
      value = ogGet.call(this)
      valueSet = true
      return value
    }
    const ogSet = descriptor.set
    const set = ogSet
      ? function set(val: any) {
          value = val
        }
      : undefined
    Object.defineProperty(target, propertyKey, { get, set })
  }
}
