import { NEVER, Observable } from 'rxjs'

import { AnyCondition } from '../any-condition'

export abstract class ConditionCompletionStateFactory<TCondition extends AnyCondition = AnyCondition> {
  public getComplete(condition: AnyCondition): Observable<boolean> {
    const canHandleStep = this.canHandleCondition(condition)
    console.log(`[${this.constructor.name}] canHandleCondition`, condition, canHandleStep)
    return this.canHandleCondition(condition) ? this.createCompleteStream(condition) : NEVER
  }

  protected abstract canHandleCondition(condition: AnyCondition): condition is TCondition
  protected abstract createCompleteStream(available: TCondition): Observable<boolean>
}
