import { NgModule } from '@angular/core'

import { AuthHandlerModule } from '../auth-handler'

import { HomeComponent } from './home.component'
import { routing } from './home.routing'

@NgModule({
  declarations: [HomeComponent],
  imports: [AuthHandlerModule, routing],
  exports: [HomeComponent],
})
export class HomeModule {}
