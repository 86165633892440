import { ChangeDetectionStrategy, Component, ElementRef, forwardRef, Inject, Input, OnInit } from '@angular/core'

import { Chapter, ChapterComponent, GuideService, Story } from '../guide'

@Component({
  selector: 'guide-modal',
  templateUrl: './guide-modal.component.pug',
  styleUrls: ['./guide-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  providers: [{ provide: ChapterComponent, useExisting: forwardRef(() => GuideModalComponent) }],
})
export class GuideModalComponent extends ChapterComponent implements OnInit {
  private _showModal: boolean
  public get showModal(): boolean {
    return this._showModal
  }
  public set showModal(showModal: boolean) {
    console.log('[GuideModalComponent] set showModal', showModal)
    this._showModal = showModal
  }

  constructor(
    guide: GuideService,
    @Inject(Story) story: Story,
    @Inject(Chapter) chapter: Chapter,
    elementRef: ElementRef,
  ) {
    super(guide, story, chapter, elementRef)
  }

  public ngOnInit(): void {
    this.showModal = true
  }

  public continue(): void {
    this.showModal = false
  }
}
