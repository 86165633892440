import { Injectable } from '@angular/core'
import { distinctUntilChanged, map, shareReplay, tap } from 'rxjs/operators'
import { Observable } from 'rxjs'

import { DefaultMap } from './default-map'
import { GuideBeacon } from './guide-beacon'
import { ObservableSet } from './rxjs'

@Injectable({ providedIn: 'root' })
export class GuideBeaconsService {
  private readonly beacons = new ObservableSet<GuideBeacon>()
  private readonly getMap = new DefaultMap<string, Observable<GuideBeacon>>()

  public readonly beacons$ = this.beacons.values$.pipe(
    tap((beacons) => console.log('[GuideBeaconsService] beacons$', beacons)),
    shareReplay(1),
  )

  public register(beacon: GuideBeacon): void {
    console.log('[GuideBeaconsService] register', beacon.name, beacon)
    this.beacons.add(beacon)
  }

  public unregister(beacon: GuideBeacon): void {
    console.log('[GuideBeaconsService] unregister', beacon.name, beacon)
    this.beacons.delete(beacon)
  }

  public get(name: string): Observable<GuideBeacon> {
    return this.getMap.get(name, () =>
      this.beacons$.pipe(
        map((beacons) => beacons.find((beacon) => beacon.name === name)),
        distinctUntilChanged(),
        tap((beacon) => console.log('[GuideBeaconsService] get$', name, beacon)),
        shareReplay(1),
      ),
    )
  }
}
