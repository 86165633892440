import { HTTP_INTERCEPTORS, HttpInterceptor } from '@angular/common/http'
import { from, of } from 'rxjs'
import { map, switchMap, take } from 'rxjs/operators'

import { AuthInterceptor } from './auth.interceptor'
import { AuthService } from './auth.service'

export function firebaseAuthInterceptorFactory(auth: AuthService): HttpInterceptor {
  const token$ = auth.currentUser$.pipe(
    switchMap((user) => {
      if (!user) {
        return of(undefined)
      }
      return from(user.getIdToken()).pipe(map((token) => `Bearer ${token}`))
    }),
    take(1),
  )
  return new AuthInterceptor(/.*/, token$)
}

export const FirebaseAuthInterceptor = {
  provide: HTTP_INTERCEPTORS,
  useFactory: firebaseAuthInterceptorFactory,
  deps: [AuthService],
  multi: true,
}
