import { MonoTypeOperatorFunction, Observable, of } from 'rxjs'
import { filter, mapTo, mergeMap, pluck } from 'rxjs/operators'

export function exists<T>(): MonoTypeOperatorFunction<T>
export function exists<T, K1 extends keyof T>(k1: K1): MonoTypeOperatorFunction<T>
export function exists<T, K1 extends keyof T, K2 extends keyof T[K1]>(k1: K1, k2: K2): MonoTypeOperatorFunction<T>
export function exists<T, K1 extends keyof T, K2 extends keyof T[K1], K3 extends keyof T[K1][K2]>(
  k1: K1,
  k2: K2,
  k3: K3,
): MonoTypeOperatorFunction<T>
export function exists<
  T,
  K1 extends keyof T,
  K2 extends keyof T[K1],
  K3 extends keyof T[K1][K2],
  K4 extends keyof T[K1][K2][K3]
>(k1: K1, k2: K2, k3: K3, k4: K4): MonoTypeOperatorFunction<T>
export function exists<
  T,
  K1 extends keyof T,
  K2 extends keyof T[K1],
  K3 extends keyof T[K1][K2],
  K4 extends keyof T[K1][K2][K3],
  K5 extends keyof T[K1][K2][K3][K4]
>(k1: K1, k2: K2, k3: K3, k4: K4, k5: K5): MonoTypeOperatorFunction<T>
export function exists<
  T,
  K1 extends keyof T,
  K2 extends keyof T[K1],
  K3 extends keyof T[K1][K2],
  K4 extends keyof T[K1][K2][K3],
  K5 extends keyof T[K1][K2][K3][K4],
  K6 extends keyof T[K1][K2][K3][K4][K5]
>(k1: K1, k2: K2, k3: K3, k4: K4, k5: K5, k6: K6): MonoTypeOperatorFunction<T>
export function exists<T>(...props: string[]): MonoTypeOperatorFunction<T> {
  return (source$: Observable<T>): Observable<T> => {
    const filterOp: MonoTypeOperatorFunction<T> = filter((value) => !!value)
    if (props.length) {
      return source$.pipe(mergeMap((value) => of(value).pipe(pluck(...props), filterOp, mapTo(value))))
    }

    return source$.pipe(filterOp)
  }
}
