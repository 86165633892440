import { HttpClient } from '@angular/common/http'
import { Inject, Injectable } from '@angular/core'
import { ModelBuilder } from '@dandi/model-builder'
import { Me } from '@fmb/models'
import { Observable } from 'rxjs'

import { DandiModelBuilder, EndpointBase } from '../shared/app-common'

@Injectable({ providedIn: 'root' })
export class MeEndpoint extends EndpointBase<Me> {
  constructor(http: HttpClient, @Inject(DandiModelBuilder) mb: ModelBuilder) {
    super(http, mb, Me)
  }

  public fetch(): Observable<Me> {
    return this.http
      .get<Me>('me', { params: { _embedded: ['budgets.items'] } })
      .pipe(this.mapResource)
  }

  public delete(): Observable<Me> {
    return this.http.delete<Me>('me').pipe(this.mapResource)
  }
}
