import { ChangeDetectionStrategy, Component, ElementRef, Inject } from '@angular/core'

import { GuideService } from './guide.service'
import { Chapter } from './model'
import { Story } from './story'

@Component({
  selector: 'guide-chapter',
  template: '',
  styleUrls: ['./chapter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChapterComponent {
  public readonly element: HTMLElement

  constructor(
    protected readonly guide: GuideService,
    @Inject(Story) public readonly story: Story,
    @Inject(Chapter) public readonly chapter: Chapter,
    protected readonly elementRef: ElementRef,
  ) {
    this.element = this.elementRef.nativeElement
  }
}
