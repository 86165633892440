import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'

import { ModalModule } from '../shared/modal'

import { ChapterComponent } from './chapter.component'
import { ChapterStepComponent } from './chapter-step.component'
import { ChapterStepButtonDirective } from './chapter-step-button.directive'
import { ChapterStepMenuComponent } from './chapter-step-menu.component'
import { CONDITION_COMPLETION_STATE_FACTORY_PROVIDERS } from './condition/completion-state'
import { GuideComponent } from './guide.component'
import { GuideBeaconDirective } from './guide-beacon.directive'
import { GuideModalComponent } from './guide-modal.component'
import { STEP_COMPLETION_STATE_FACTORY_PROVIDERS } from './step-completion-state'

@NgModule({
  declarations: [
    ChapterComponent,
    ChapterStepComponent,
    ChapterStepButtonDirective,
    ChapterStepMenuComponent,
    GuideBeaconDirective,
    GuideComponent,
    GuideModalComponent,
  ],
  entryComponents: [ChapterComponent, ChapterStepComponent, GuideModalComponent],
  imports: [CommonModule, ModalModule],
  exports: [
    ChapterComponent,
    ChapterStepComponent,
    ChapterStepButtonDirective,
    ChapterStepMenuComponent,
    GuideBeaconDirective,
    GuideComponent,
    GuideModalComponent,
  ],
  providers: [...CONDITION_COMPLETION_STATE_FACTORY_PROVIDERS, ...STEP_COMPLETION_STATE_FACTORY_PROVIDERS],
})
export class GuideModule {}
