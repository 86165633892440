import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'

import { GuideModule } from '../../guide'
import { AppCommonModule } from '../../shared/app-common'

import { EditItemIntervalComponent } from './edit-item-interval.component'

@NgModule({
  declarations: [EditItemIntervalComponent],
  exports: [EditItemIntervalComponent],
  imports: [AppCommonModule, CommonModule, GuideModule, ReactiveFormsModule],
})
export class EditItemIntervalModule {}
