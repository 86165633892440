import { HttpClient } from '@angular/common/http'
import { Inject, Injectable } from '@angular/core'
import { ModelBuilder } from '@dandi/model-builder'
import { Me, Simulation, SimulationRequestOptions } from '@fmb/models'
import { Store } from '@ngxs/store'
import { Observable } from 'rxjs'
import { filter, mergeMap, share, take } from 'rxjs/operators'

import { MeState } from '../../me'
import { DandiModelBuilder, EndpointBase } from '../../shared/app-common'

@Injectable({ providedIn: 'root' })
export class SimulationEndpoint extends EndpointBase<Simulation> {
  constructor(http: HttpClient, private store: Store, @Inject(DandiModelBuilder) mb: ModelBuilder) {
    super(http, mb, Simulation)
  }

  public runSimulation(options: SimulationRequestOptions = {}): Observable<Simulation> {
    const params = this.getParamsFromOptions(options)
    return this.store.select<Me>(MeState).pipe(
      filter((me) => !!me),
      take(1),
      mergeMap((me) => this.http.post(me.getLink('simulation').href, undefined, { params })),
      this.mapResource,
      share(),
    )
  }

  private getParamsFromOptions(options: SimulationRequestOptions): { [key: string]: string | string[] } {
    const params: { [key: string]: string | string[] } = {}

    if (options.safetyNet) {
      params.safetyNet = options.safetyNet.toString()
    }
    if (options.sharingMode) {
      params.sharingMode = options.sharingMode.toString()
    }
    if (options.startingBalance) {
      params.startingBalance = options.startingBalance.toString()
    }
    if (options.startsOn) {
      params.startsOn = options.startsOn.toISODate()
    }

    return params
  }
}
