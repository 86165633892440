import {
  ApplicationRef,
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  Inject,
  Injectable,
  InjectFlags,
  InjectionToken,
  Injector,
  Type,
  ViewContainerRef,
} from '@angular/core'
import { Chapter, ContentStep, isContentStep } from './model'
import { Subject } from 'rxjs'
import { shareReplay } from 'rxjs/operators'
import { Story } from './story'

export const ComponentType = new InjectionToken<Type<any>>('ComponentType')

@Injectable()
export class ComponentRefFactory<TComponent> {
  public static create<TComponent>(
    parentInjector: Injector,
    story: Story,
    chapter: Chapter,
    step: ContentStep,
    componentType: Type<TComponent>,
  ): ComponentRefFactory<TComponent> {
    const providers = [
      { provide: Story, useValue: story },
      { provide: Chapter, useValue: chapter },
      { provide: ContentStep, useValue: step },
      { provide: ComponentType, useValue: componentType },
      {
        provide: ComponentRefFactory,
        deps: [ComponentFactoryResolver, ApplicationRef, Injector, ComponentType, Story, Chapter, ContentStep],
      },
    ]
    const injector = Injector.create({ providers, parent: parentInjector })
    return injector.get(ComponentRefFactory)
  }

  private readonly componentFactory: ComponentFactory<TComponent>
  private readonly componentInjector: Injector
  private readonly rootComponentRef: ComponentRef<unknown>
  private readonly viewContainerRef: ViewContainerRef
  private readonly destroy$$ = new Subject<void>()
  public readonly destroy$ = this.destroy$$.asObservable().pipe(shareReplay(1))

  private _componentRef: ComponentRef<TComponent>
  public getComponentRef(create: boolean): ComponentRef<TComponent> {
    if (this._componentRef || !this.componentFactory || !create) {
      return this._componentRef
    }

    console.log('[ComponentRefFactory] getComponentRef creating component', this.step.id)
    this._componentRef = this.viewContainerRef.createComponent(this.componentFactory, undefined, this.componentInjector)
    this._componentRef.onDestroy(() => {
      this.destroy$$.next()
      this.destroy$$.complete()
      this._componentRef = undefined
    })
    return this._componentRef
  }

  public get componentIndex(): number {
    if (!this._componentRef) {
      return -1
    }
    return this.viewContainerRef.indexOf(this._componentRef.hostView)
  }

  constructor(
    private readonly cfr: ComponentFactoryResolver,
    appRef: ApplicationRef,
    injector: Injector,
    @Inject(ComponentType)
    private readonly componentType: Type<TComponent>,
    @Inject(Story) public readonly story: Story,
    @Inject(Chapter) public readonly chapter: Chapter,
    @Inject(ContentStep) public readonly step: ContentStep,
  ) {
    if (isContentStep(step)) {
      const providers = [
        {
          provide: Story,
          useValue: story,
        },
        {
          provide: Chapter,
          useValue: chapter,
        },
        {
          provide: ContentStep,
          useValue: step,
        },
      ]
      const [rootComponentRef] = appRef.components
      this.rootComponentRef = rootComponentRef
      this.viewContainerRef = rootComponentRef.injector.get(ViewContainerRef, undefined, InjectFlags.Host)
      this.componentInjector = Injector.create({ providers, parent: injector })
      this.componentFactory = this.cfr.resolveComponentFactory(this.componentType)
    }
  }

  public attach(): ComponentRef<TComponent> {
    const componentRef = this.getComponentRef(true)
    if (componentRef) {
      if (this.componentIndex < 0) {
        this.viewContainerRef.insert(componentRef.hostView)
      }
      componentRef.hostView.detectChanges()
    }
    return componentRef
  }

  public detach(): void {
    const componentRef = this.getComponentRef(false)
    console.log('[ComponentRefFactory] detach', this.step.id, componentRef, this.componentIndex)
    if (componentRef && this.componentIndex >= 0) {
      this.viewContainerRef.detach(this.componentIndex)
    }
  }

  public destroy(): void {
    if (this._componentRef) {
      this.viewContainerRef.remove(this.componentIndex)
      this._componentRef = undefined
    }
  }
}
