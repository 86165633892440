import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { ReactiveFormsModule } from '@angular/forms'

import { GuideModule } from '../../guide'
import { AppCommonModule } from '../../shared/app-common'

import { EditItemIntervalModule } from './edit-item-interval.module'
import { EditBudgetItemComponent } from './edit-budget-item.component'
import { routing, routingProviders } from './edit-budget-item.routing'
import { ItemAmountInputPipe } from './item-amount-input.pipe'

@NgModule({
  declarations: [EditBudgetItemComponent, ItemAmountInputPipe],
  imports: [AppCommonModule, EditItemIntervalModule, CommonModule, GuideModule, ReactiveFormsModule, routing],
  providers: [routingProviders],
})
export class EditBudgetItemModule {}
