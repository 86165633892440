import { Uuid } from '@dandi/common'
import { HalModelBase, Relation, ResourceId } from '@dandi/hal'
import { Json, Property, Required } from '@dandi/model'
import { DateTime } from 'luxon'

import { BillInterval, BillIntervalModel } from '../bill-interval.model'
import { BudgetResource } from '../budget/budget.model'
import { CreatedData } from '../created-data'
import { IntervalTypeYearlyMultiplier } from '../interval-type'
import { RemovedData } from '../removed-data'

export interface BudgetItemCreationModel {
  itemId?: string | Uuid
  budgetId: string | Uuid
  name: string
  interval: BillIntervalModel
  amount: number
  yearlyAmount?: number
}

export function isExistingItem(item: BudgetItemCreationModel): item is BudgetItemResource {
  return !!item.itemId
}

export interface BudgetItemModel extends BudgetItemCreationModel, CreatedData, RemovedData {
  itemId: string | Uuid
  lastPaidOn?: number | DateTime
}

export class BudgetItemCreationRequest extends HalModelBase implements BudgetItemCreationModel {
  public static readonly resourceKey: string = 'BudgetItemCreationRequest'

  constructor(source?: Partial<BudgetItemCreationModel>) {
    super(source)

    if (this.interval) {
      if (!(this.interval instanceof BillInterval)) {
        this.interval = new BillInterval(this.interval)
      }
    }
  }

  @Property(String)
  @Required()
  public name: string

  @Property(Uuid)
  @ResourceId(BudgetResource, 'budget')
  public budgetId: Uuid

  @Relation(BudgetResource)
  public get budget(): BudgetResource {
    return this.getEmbedded('budget')
  }

  @Property(BillInterval)
  @Json()
  @Required()
  public interval: BillIntervalModel

  public get nextDueOn(): DateTime {
    return BillIntervalModel.getNextDueOn(this.interval)
  }

  private _amount: number
  @Property(Number)
  @Required()
  public set amount(amount: number) {
    if (amount !== this._amount) {
      this._amount = amount
      this._yearlyAmount = undefined
    }
  }

  public get amount(): number {
    return this._amount
  }

  private _yearlyAmount: number
  public get yearlyAmount(): number {
    if (this._yearlyAmount === undefined) {
      const multiplier = IntervalTypeYearlyMultiplier[this.interval.type]
      this._yearlyAmount = (multiplier * this.amount) / this.interval.value
    }
    return this._yearlyAmount
  }

  @Property(DateTime)
  public lastPaidOn?: DateTime

  public toJSON(): BudgetItemCreationModel {
    return {
      name: this.name,
      budgetId: this.budgetId,
      interval: this.interval,
      amount: this.amount,
    }
  }
}

export class BudgetItemResource extends BudgetItemCreationRequest implements BudgetItemCreationModel {
  public static readonly resourceKey: string = 'BudgetItemResource'

  constructor(source?: Partial<BudgetItemResource>) {
    super(source)
  }

  @Property(Uuid)
  @Required()
  @ResourceId()
  public itemId: Uuid

  @Property(Uuid)
  @ResourceId(BudgetResource, 'budget')
  public budgetId: Uuid

  @Property(DateTime)
  @Required()
  public createdOn: DateTime

  @Property(Uuid)
  @Required()
  public createdBy: Uuid

  @Property(DateTime)
  @Required()
  public modifiedOn: DateTime

  @Property(Uuid)
  @Required()
  public modifiedBy: Uuid

  @Property(DateTime)
  public removedOn?: DateTime

  @Property(Uuid)
  public removedBy?: Uuid

  public toJSON(): BudgetItemModel {
    return Object.assign(super.toJSON(), this)
  }
}
