import { Constructor } from '@dandi/common'

import { MODEL_BUILDER_INSTANCE } from './model-builder'

const DESERIALIZED_MODEL_KEY = '__DESERIALIZED_MODEL'

interface SerializedModel {
  [DESERIALIZED_MODEL_KEY]: string
  model: object
}

function isSerializedModel(obj: any): obj is SerializedModel {
  return obj && typeof obj[DESERIALIZED_MODEL_KEY] === 'string' && typeof obj.model === 'object'
}

type ResourceDescriptor = Constructor & { resourceKey: string }

export class ModelSerializer {
  private static readonly mb = MODEL_BUILDER_INSTANCE
  private static readonly models = new Map<string, ResourceDescriptor>()

  public static register(...models: ResourceDescriptor[]): void {
    models.forEach((model) => {
      if (this.models.has(model.resourceKey)) {
        throw new Error(`${model.resourceKey} has already been registered with ModelSerializer`)
      }
      this.models.set(model.resourceKey, model)
    })
  }

  public static serialize(obj: any): string {
    if (obj.constructor === Object) {
      return JSON.stringify(obj)
    }
    if (!this.models.has(obj.constructor.resourceKey)) {
      throw new Error(`${obj.constructor.resourceKey} has not been registered with ModelSerializer`)
    }
    return JSON.stringify({
      [DESERIALIZED_MODEL_KEY]: obj.constructor.resourceKey,
      model: obj,
    })
  }

  public static deserialize(json: string): object {
    const obj = JSON.parse(json)
    if (!isSerializedModel(obj)) {
      return obj
    }
    const modelType = this.models.get(obj[DESERIALIZED_MODEL_KEY])
    return this.mb.constructModel(modelType, obj)
  }
}
