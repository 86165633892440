import { Inject, Injectable, InjectionToken, StaticProvider } from '@angular/core'
import { combineLatest, defer, Observable } from 'rxjs'
import { map, share, shareReplay } from 'rxjs/operators'
import { Actions } from '@ngxs/store'

import { BudgetService } from '../budget'
import { compareArray } from '../guide'
import { ConditionCompletion } from '../guide/condition'
import { ManualStep, StepData } from '../guide/model'
import { RouteHelper } from '../shared/app-common'

export const NavigateToNewItemStepMode = new InjectionToken<'bill' | 'income'>('NavigateToNewItemStepMode')

@Injectable()
export class NavigateToNewItemStepCompletion implements ConditionCompletion {
  public static providers(mode: 'bill' | 'income'): StaticProvider[] {
    return [
      {
        provide: NavigateToNewItemStepCompletion,
        deps: [BudgetService, Actions, NavigateToNewItemStepMode, RouteHelper],
      },
      { provide: NavigateToNewItemStepMode, useValue: mode },
    ]
  }

  public static step(mode: 'bill' | 'income', step: Partial<StepData<ManualStep>> = {}): StepData<ManualStep> {
    return {
      ...step,
      beacon: 'add-item',
      complete: NavigateToNewItemStepCompletion,
      providers: NavigateToNewItemStepCompletion.providers(mode),
    }
  }

  public readonly complete$: Observable<boolean>

  constructor(
    budgetService: BudgetService,
    actions: Actions,
    @Inject(NavigateToNewItemStepMode) mode: 'bill' | 'income',
    route: RouteHelper,
  ) {
    this.complete$ = defer(() => {
      const expectedPath$ = budgetService.currentBudgetId$.pipe(
        map((budgetId) => ['budget', budgetId, 'item', 'new']),
        share(),
      )

      const viaRoute$ = combineLatest([route.routeData$, expectedPath$]).pipe(
        map(([data, expectedPath]) => compareArray(data.path, expectedPath)),
      )

      return viaRoute$.pipe(shareReplay(1))
    })
  }
}
