import { Uuid } from '@dandi/common'
import { HalModelBase, ListRelation, Relation } from '@dandi/hal'
import { Property, Required } from '@dandi/model'

import { BudgetResource } from './budget'
import { Simulation, UserSimulation } from './simulation'

export class Me extends HalModelBase {
  public static readonly resourceKey = 'Me'

  @Property(Uuid)
  @Required()
  public userId: Uuid

  @ListRelation(BudgetResource)
  public get budgets(): BudgetResource[] {
    return this.getEmbedded('budgets')
  }

  @Relation(UserSimulation)
  public get simulation(): Simulation {
    return this.getEmbedded('simulation')
  }

  constructor(source?: { userId: Uuid }) {
    super()
    Object.assign(this, source)
  }
}
