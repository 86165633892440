import { isBeaconStep } from './beacon-step'
import { Step } from './step'

export enum ButtonStepType {
  beacon = 'beacon',
  step = 'step',
}

export interface StepButton {
  type: ButtonStepType.step
  cssClasses?: string
  text?: string
}

export interface ButtonStep extends Step {
  button: ButtonStepType | StepButton
  beacon: string
}

export interface StepButtonStep extends ButtonStep {
  button: ButtonStepType.step | StepButton
}

export interface BeaconButtonStep extends ButtonStep {
  button: ButtonStepType.beacon
}

function isButtonStepInternal(obj: any): obj is ButtonStep {
  return obj.button && isBeaconStep(obj)
}

export function isBeaconButtonStepInternal(obj: any): obj is BeaconButtonStep {
  return obj.button === ButtonStepType.beacon
}

function isStepButtonStepInternal(obj: any): obj is StepButtonStep {
  return obj.button && (obj.button === ButtonStepType.step || obj.button.type === ButtonStepType.step)
}

export function isBeaconButtonStep(obj: any): obj is BeaconButtonStep {
  return isButtonStepInternal(obj) && isBeaconButtonStepInternal(obj)
}

export function isStepButtonStep(obj: any): obj is StepButtonStep {
  return isButtonStepInternal(obj) && isStepButtonStepInternal(obj)
}

export function isButtonStep(obj: any): obj is ButtonStep {
  return isButtonStepInternal(obj) && (isBeaconButtonStepInternal(obj) || isStepButtonStepInternal(obj))
}
