import { HttpClient } from '@angular/common/http'
import { Inject, Injectable } from '@angular/core'
import { ModelBuilder } from '@dandi/model-builder'
import { BudgetResource, BudgetCreationRequest } from '@fmb/models'
import { Observable } from 'rxjs'

import { DandiModelBuilder, EndpointBase } from '../shared/app-common'

@Injectable({ providedIn: 'root' })
export class BudgetEndpoint extends EndpointBase<BudgetResource> {
  constructor(http: HttpClient, @Inject(DandiModelBuilder) mb: ModelBuilder) {
    super(http, mb, BudgetResource)
  }

  public create(name: string): Observable<BudgetResource> {
    const budget = new BudgetCreationRequest({
      name,
    })
    return this.http.post<BudgetResource>('budget', budget).pipe(this.mapResource)
  }
}
