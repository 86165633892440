import { PositionDepth, PositionDistance, PositionMargin, PositionScroll } from './position-dimensions'

export interface Axis {
  readonly name: keyof AxisStatic
  readonly distance: PositionDistance
  readonly depth: PositionDepth
  readonly scroll: PositionScroll
  readonly margin: PositionMargin
}

export interface AxisStatic {
  readonly x: Axis
  readonly y: Axis
}

export type PerAxis<T> = {
  [TDim in keyof AxisStatic]: T
}

export function getPerAxis<T extends string | number, TPerAxis extends PerAxis<T> = PerAxis<T>>(
  input: T | TPerAxis,
  defaultValue: T,
): PerAxis<T> {
  if (typeof input === 'object' || typeof input === 'undefined') {
    return {
      x: input?.x || defaultValue,
      y: input?.y || defaultValue,
    }
  }
  return { x: input, y: input }
}

class AxisImpl {
  public static readonly x = new AxisImpl('x', 'offsetLeft', 'offsetWidth', 'scrollLeft', 'margin-left')
  public static readonly y = new AxisImpl('y', 'offsetTop', 'offsetHeight', 'scrollTop', 'margin-top')

  private constructor(
    public readonly name: keyof AxisStatic,
    public readonly distance: PositionDistance,
    public readonly depth: PositionDepth,
    public readonly scroll: PositionScroll,
    public readonly margin: PositionMargin,
  ) {}

  public toString(): string {
    return this.name
  }
}

export const Axis: AxisStatic = AxisImpl
