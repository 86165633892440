import { Directive, HostBinding } from '@angular/core'

@Directive({
  selector: 'a[external]',
})
export class ExternalLinkDirective {
  @HostBinding()
  public readonly target = 'blank'

  @HostBinding('rel')
  public readonly rel = 'nofollow'
}
