import { Directive, ElementRef, HostBinding, HostListener, Input, OnDestroy, Renderer2 } from '@angular/core'
import { Observable, Subject } from 'rxjs'

import { ButtonStepType, StepButton } from './model'

const STEP_BUTTON_DEFAULTS: StepButton = Object.freeze({
  type: ButtonStepType.step,
  text: 'Continue',
  cssClasses: 'btn btn-info',
})

@Directive({
  selector: 'button[chapterStepButton]',
})
export class ChapterStepButtonDirective implements OnDestroy {
  @Input('chapterStepButton')
  public set button(button: StepButton) {
    button = Object.assign({}, STEP_BUTTON_DEFAULTS, button)
    this.renderer.appendChild(this.elementRef.nativeElement, this.renderer.createText(button.text))
    this.cssClasses = button.cssClasses
  }

  @HostBinding('type')
  public readonly type = 'button'

  public readonly click$: Observable<MouseEvent>

  @HostBinding('class')
  private cssClasses: string

  private readonly click$$ = new Subject<MouseEvent>()

  constructor(private readonly elementRef: ElementRef, private readonly renderer: Renderer2) {
    this.click$ = this.click$$.asObservable()
  }

  public ngOnDestroy(): void {
    this.click$$.complete()
  }

  @HostListener('click', ['$event'])
  private onClick(e: MouseEvent): void {
    this.click$$.next(e)
  }
}
