import { animate, state, style, transition, trigger } from '@angular/animations'
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Inject,
  OnDestroy,
  OnInit,
  ViewContainerRef,
} from '@angular/core'
import { Title } from '@angular/platform-browser'
import { User } from '@firebase/auth-types'
import { merge, Observable, Subscription } from 'rxjs'
import { distinctUntilChanged, mapTo, pluck, share, shareReplay, startWith, tap } from 'rxjs/operators'

import { NavInfo, NavInfoService } from '../shared'
import { AnalyticsService } from '../shared/analytics'
import { ErrorInfo, Errors$, NavState, NavStateService, RouteData, RouteHelper } from '../shared/app-common'
import { AuthService } from '../shared/auth'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.pug',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('loading', [
      state('*', style({ opacity: 0, display: 'none' })),
      state('true', style({ opacity: 1, display: '' })),
      transition('* => true', animate('500ms ease-in-out')),
      transition('true => *', animate('500ms ease-in-out')),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {
  public readonly navState$: Observable<NavState>
  public readonly loading$: Observable<boolean>
  public readonly error$: Observable<ErrorInfo>
  public readonly routeData$: Observable<RouteData>
  public readonly currentUser$: Observable<User>
  public readonly navInfo$: Observable<NavInfo>

  private readonly run$: Observable<void>
  private run: Subscription
  private routeData: RouteData

  @HostBinding('class.full-screen-route')
  private get fullScreenRoute(): boolean {
    return this.routeData?.fullScreen
  }

  @HostBinding('class.height-constrained')
  private get heightConstrained(): boolean {
    return this.routeData?.fullScreen
  }

  @HostBinding('class.layered')
  private get layered(): boolean {
    return this.routeData?.layered
  }

  constructor(
    private readonly title: Title,
    private readonly routeHelper: RouteHelper,
    authService: AuthService,
    navState: NavStateService,
    navInfo: NavInfoService,
    analytics: AnalyticsService,
    public readonly viewContainerRef: ViewContainerRef,
    private readonly changeDetectorRef: ChangeDetectorRef,
    @Inject(Errors$) public readonly appError$: Observable<ErrorInfo>,
  ) {
    this.currentUser$ = authService.currentUser$
    this.navInfo$ = navInfo.navInfo$
    this.navState$ = navState.navState$
    this.loading$ = this.navState$.pipe(pluck('loading'), startWith(true), distinctUntilChanged(), shareReplay(1))
    this.routeData$ = routeHelper.routeData$
    this.error$ = merge(this.navState$.pipe(pluck('error')), appError$).pipe(distinctUntilChanged(), share())
    const cssClass$ = this.routeHelper.routeData$.pipe(
      tap((data) => {
        this.title.setTitle(data.title)
        this.routeData = data
      }),
    )
    this.run$ = merge(cssClass$, analytics.run$).pipe(mapTo(undefined))

    // TODO: Remove
    Object.defineProperties(window, {
      markForCheck: {
        value: (): void => this.changeDetectorRef.markForCheck(),
      },
      detectChanges: {
        value: (): void => this.changeDetectorRef.detectChanges(),
      },
    })
  }

  public ngOnInit(): void {
    this.run = this.run$.subscribe()
  }

  public ngOnDestroy(): void {
    this.run.unsubscribe()
    this.run = undefined
  }
}
