import { AnalyticsAction } from '../../shared/analytics'

export enum GuideActionType {
  guide = 'Guide',
  story = 'Guide: Story',
  chapter = 'Guide: Chapter',
}

export type GuideActionParameters<T extends new (...args: any) => any> = T extends new (
  userKey: string,
  ...args: infer P
) => any
  ? P
  : never

interface GuideActionConstructor {
  readonly type: string
  new (...args: any[]): GuideAction
}

export abstract class GuideActionBase extends AnalyticsAction {
  public static getType(actionType: GuideActionType, subType: string): string {
    return `[${actionType}] ${subType}`
  }

  constructor(public readonly userKey: string) {
    super({ category: 'Guide' })
    this._analyticsData.action = (this.constructor as GuideActionConstructor).type
  }
}

export class GuideAction extends GuideActionBase {
  public static getType(subType: string): string {
    return GuideActionBase.getType(GuideActionType.guide, subType)
  }
}

export class StoryAction extends GuideAction {
  public static getType(subType: string): string {
    return GuideActionBase.getType(GuideActionType.story, subType)
  }

  constructor(userKey: string, public readonly storyId: string) {
    super(userKey)
    this._analyticsData.label = storyId
  }
}

export class ChapterAction extends StoryAction {
  public static getType(subType: string): string {
    return GuideActionBase.getType(GuideActionType.chapter, subType)
  }

  constructor(userKey: string, storyId: string, public readonly chapterId: string) {
    super(userKey, storyId)
    this._analyticsData.label = `${storyId}: ${chapterId}`
  }
}
