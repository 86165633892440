import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit } from '@angular/core'

@Component({
  selector: 'modal-dialog',
  template: '<ng-content *ngIf="show"></ng-content>',
  styleUrls: ['./modal-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalDialogComponent implements OnInit {
  @HostBinding('class.modal-dialog-centered')
  @Input()
  public centered: boolean

  @HostBinding('class.modal-dialog-scrollable')
  @Input()
  public scrollable: boolean

  @HostBinding('class.modal-dialog')
  public readonly modalDialog = true

  @HostBinding('attr.role')
  public readonly role = 'document'

  private _show: boolean
  public get show(): boolean {
    return this._show
  }

  public ngOnInit(): void {
    // this is required to prevent the modal from flashing up unstyled by centered/scrollable
    this._show = true
  }
}
