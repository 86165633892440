import { Injectable } from '@angular/core'
import { BudgetResource } from '@fmb/models'
import { Store } from '@ngxs/store'
import { Observable, combineLatest, of } from 'rxjs'
import { distinctUntilChanged, filter, first, map, mergeMap, pluck, shareReplay } from 'rxjs/operators'

import { RouteHelper } from '../shared/app-common'

import { BudgetState, BudgetStateModel } from './budget.state'

@Injectable({ providedIn: 'root' })
export class BudgetService {
  public readonly budgets$: Observable<BudgetResource[]>
  public readonly currentBudget$: Observable<BudgetResource>
  public readonly currentBudgetId$: Observable<string>

  constructor(private store: Store, routeHelper: RouteHelper) {
    const budgetIdFromRouterEvents = routeHelper.routeData$.pipe(pluck('params', 'budgetId'))
    this.currentBudgetId$ = budgetIdFromRouterEvents.pipe(
      mergeMap((budgetId) => (budgetId ? of(budgetId) : this.budgets$.pipe(first(), pluck(0), pluck('budgetId')))),
      distinctUntilChanged(),
      shareReplay(1),
    )

    this.budgets$ = store.select<BudgetStateModel>(BudgetState).pipe(
      filter((state) => !!state && !!state.budgetList),
      pluck('budgetList'),
      distinctUntilChanged(),
      shareReplay(1),
    )

    this.currentBudget$ = combineLatest([this.currentBudgetId$, this.budgets$]).pipe(
      map(([budgetId, budgets]) => budgets.find((budget) => budget.budgetId.toString() === budgetId.toString())),
      shareReplay(1),
    )
  }
}
