import { ProcessedItem } from '@fmb/models'
import { Action, State, StateContext } from '@ngxs/store'
import { Observable } from 'rxjs'
import { mergeMap, share } from 'rxjs/operators'

import { LoadSimulation, SignOut, SimulationComplete } from '../../action'

import { SimulationEndpoint } from './simulation.endpoint'

export interface SimulationStateModel {
  requiredStartingBalance: number
  depositContributions: { [itemId: string]: number }
  yearlyTotalBills: number
  yearlyTotalDeposits: number
  minimumBalanceItems: ProcessedItem[]
}

@State<SimulationStateModel>({
  name: 'simulation',
  defaults: {
    requiredStartingBalance: undefined,
    depositContributions: undefined,
    yearlyTotalBills: undefined,
    yearlyTotalDeposits: undefined,
    minimumBalanceItems: undefined,
  },
})
export class SimulationState {
  constructor(private simulationEndpoint: SimulationEndpoint) {}

  @Action(SimulationComplete)
  public simulationComplete(context: StateContext<SimulationStateModel>, { simulation }: SimulationComplete): void {
    context.patchState({
      requiredStartingBalance: simulation.result.requiredStartingBalance,
      yearlyTotalBills: simulation.result.yearlyTotalBills,
      yearlyTotalDeposits: simulation.result.yearlyTotalDeposits,
      depositContributions: [...Object.entries(simulation.result.depositContributions)].reduce(
        (result, [itemId, amount]) => {
          result[itemId.toString()] = amount
          return result
        },
        {},
      ),
      minimumBalanceItems: simulation.overageItems,
    })
  }

  @Action(LoadSimulation)
  public loadSimulation(context: StateContext<SimulationStateModel>, { options }: LoadSimulation): Observable<void> {
    return this.simulationEndpoint.runSimulation(options).pipe(
      mergeMap((simulation) => context.dispatch(new SimulationComplete(simulation))),
      share(),
    )
  }

  @Action(SignOut)
  public signOut(context: StateContext<SimulationStateModel>): void {
    context.setState(undefined)
  }
}
