import { BudgetItemResource } from '@fmb/models'
import { AnalyticsAction } from '../shared/analytics'

export class DeleteBudgetItem extends AnalyticsAction {
  public static readonly type = '[BudgetItem] Delete'

  constructor(public readonly item: BudgetItemResource) {
    super({ category: 'BudgetItem', action: 'Delete' })
  }
}
