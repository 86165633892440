import { HttpClient } from '@angular/common/http'
import { Constructor } from '@dandi/common'
import { HalModelBase } from '@dandi/hal'
import { ModelBuilder } from '@dandi/model-builder'
import { OperatorFunction } from 'rxjs'
import { map } from 'rxjs/operators'

export class EndpointBase<TResource extends HalModelBase> {
  protected readonly mapResource: OperatorFunction<unknown, TResource> = map((source) =>
    this.mb.constructModel(this.resourceType, source),
  )

  protected constructor(
    protected http: HttpClient,
    private mb: ModelBuilder,
    private resourceType: Constructor<TResource>,
  ) {}
}
