import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot } from '@angular/router'
import { Actions } from '@ngxs/store'
import { ActionStatus } from '@ngxs/store/src/actions-stream'
import { merge, Observable } from 'rxjs'
import { filter, pluck, tap } from 'rxjs/operators'

import { RouteHelper } from '../app-common'

declare const ga: (...args: unknown[]) => void

@Injectable({ providedIn: 'root' })
export class AnalyticsService {
  public readonly run$: Observable<void>

  constructor(route: RouteHelper, actions: Actions) {
    const pageViews$ = route.snapshot$.pipe(tap(this.navigationInterceptor.bind(this)))
    const events$ = actions.pipe(
      filter((state) => state.status === ActionStatus.Successful && state.action.analyticsFields),
      pluck('action', 'analyticsFields'),
      tap((fields) => ga('send', 'event', fields)),
      tap((fields) => console.debug('[AnalyticsService] events$', fields)),
    )

    this.run$ = merge(pageViews$, events$)
  }

  private navigationInterceptor(snapshot: ActivatedRouteSnapshot): void {
    const routePath = `/${this.getRoutePath(snapshot).join('/')}`
    ga('set', 'page', routePath)
    ga('send', 'pageView')
  }

  private getRoutePath(snapshot: ActivatedRouteSnapshot): string[] {
    const segmentPath = []
    if (snapshot.routeConfig?.path) {
      segmentPath.push(snapshot.routeConfig.path)
    }
    if (snapshot.firstChild) {
      segmentPath.push(...this.getRoutePath(snapshot.firstChild))
    }
    return segmentPath
  }
}
