import { Component, HostBinding } from '@angular/core'

@Component({
  selector: 'modal-body',
  template: '<ng-content></ng-content>',
  styleUrls: ['./modal-body.component.scss'],
})
export class ModalBodyComponent {
  @HostBinding('class.modal-body')
  public readonly modalBody = true
}
