import { ReplaySubject } from 'rxjs'
import { debounceTime, shareReplay } from 'rxjs/operators'

export class ObservableSet<T = unknown> extends Set<T> {
  private readonly values$$ = new ReplaySubject<T[]>(1)
  private lastSize: number

  public readonly values$ = this.values$$.asObservable().pipe(debounceTime(1), shareReplay(1))

  constructor(values?: T[]) {
    super(values)

    this.update()
  }

  public add(value: T): this {
    const exists = this.has(value)
    return super.add(value).update(exists)
  }

  public delete(value: T): boolean {
    if (super.delete(value)) {
      this.update()
      return true
    }
    return false
  }

  public clear(): void {
    super.clear()
    this.update()
  }

  protected update(force = false): this {
    if (force || this.size !== this.lastSize) {
      this.values$$.next([...this.values()])
      this.lastSize = this.size
    }
    return this
  }
}
