export enum IntervalType {
  day = 1,
  week = 2,
  month = 3,
}

export const IntervalTypeObjectKeys: { [type in IntervalType]: string } = {
  [IntervalType.day]: 'days',
  [IntervalType.week]: 'weeks',
  [IntervalType.month]: 'months',
}

export const IntervalTypeYearlyMultiplier: {
  [type in IntervalType]: number
} = {
  [IntervalType.day]: 365,
  [IntervalType.week]: 52,
  [IntervalType.month]: 12,
}
