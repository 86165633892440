import { Injectable } from '@angular/core'
import { AuthProvider, User } from '@firebase/auth-types'
import { Store } from '@ngxs/store'
import * as Sentry from '@sentry/browser'
import { Observable, of } from 'rxjs'
import { shareReplay, switchMap } from 'rxjs/operators'

import { LoadMe } from '../../action'

import { FirebaseAuthService } from './firebase-auth.service'

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public readonly currentUser$: Observable<User>

  constructor(private readonly firebaseAuth: FirebaseAuthService, store: Store) {
    this.currentUser$ = firebaseAuth.authState$.pipe(
      switchMap((user) => {
        Sentry.configureScope((scope) => scope.setUser(user))
        if (user) {
          // FIXME can't dispatch LoadMe until user is emitted
          store.dispatch(new LoadMe())
        }
        return of(user)
      }),
      shareReplay(1),
    )
  }

  public signIn(provider: AuthProvider): Observable<boolean> {
    return this.firebaseAuth.signIn(provider)
  }

  public signOut(): Observable<boolean> {
    return this.firebaseAuth.signOut()
  }
}
