import { InjectionToken } from '@angular/core'
import { fromEvent, Observable } from 'rxjs'
import { map, share } from 'rxjs/operators'

export interface ResponsiveWindow {
  readonly resize$: Observable<Screen>
  readonly screen: Screen
}

export const ResponsiveWindow = new InjectionToken<ResponsiveWindow>('ResponsiveWindow')

export class ResponsiveBrowserWindow {
  public readonly resize$ = fromEvent(window, 'resize').pipe(
    map(() => this.screen),
    share(),
  )
  public readonly screen = window.screen
}
