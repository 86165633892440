import { ReplaySubject } from 'rxjs'
import { debounceTime, shareReplay } from 'rxjs/operators'

export class ObservableMap<TKey = unknown, TValue = unknown> extends Map<TKey, TValue> {
  private readonly values$$ = new ReplaySubject<TValue[]>(1)
  private lastSize: number

  public readonly values$ = this.values$$.asObservable().pipe(debounceTime(1), shareReplay(1))

  constructor(entries?: [TKey, TValue][]) {
    super(entries)

    this.update()
  }

  public set(key: TKey, value: TValue): this {
    const exists = this.get(key)
    const changed = exists && exists !== value
    return super.set(key, value).update(changed)
  }

  public delete(key: TKey): boolean {
    if (super.delete(key)) {
      this.update()
      return true
    }
    return false
  }

  public clear(): void {
    super.clear()
    this.update()
  }

  protected update(force = false): this {
    if (force || this.size !== this.lastSize) {
      this.values$$.next([...this.values()])
      this.lastSize = this.size
    }
    return this
  }
}
