import { ListRelation, Relations } from '@dandi/hal'

import { BudgetResource } from './budget.model'
import { BudgetItemResource } from '../budget-item'

@Relations(BudgetResource)
export class BudgetRelations implements Partial<BudgetResource> {
  public static readonly resourceKey: string = 'BudgetRelations'

  @ListRelation(BudgetItemResource)
  public items: BudgetItemResource[]
}
