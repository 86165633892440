import { Injectable } from '@angular/core'
import { defer, Observable } from 'rxjs'
import { filter, mapTo, mergeMap, startWith, take } from 'rxjs/operators'

import { AvailableStep } from '../available-step'
import { ButtonStep, isButtonStep } from '../model'

import { StepCompletionStateFactory } from './step-completion-state-factory'

@Injectable()
export class ButtonStepCompletionStateFactory extends StepCompletionStateFactory<ButtonStep> {
  protected canHandleStep(available: AvailableStep): available is AvailableStep<ButtonStep> {
    return isButtonStep(available.step)
  }

  protected createCompleteStream(available: AvailableStep<ButtonStep>): Observable<boolean> {
    return defer(() => available.beacon$).pipe(
      filter((beacon) => !!beacon),
      mergeMap((beacon) => beacon.stepButton(available.story, available.chapter, available.step)),
      mapTo(true),
      startWith(false),
      take(2),
    )
  }
}
