import { isJsonable, Uuid } from '@dandi/common'
import { DateTime } from 'luxon'

export type ToJsonResult<T> = T extends string
  ? T
  : T extends number
  ? T
  : T extends boolean
  ? T
  : T extends DateTime
  ? number
  : T extends Uuid
  ? string
  : JSONObject<T>

export type JSONObject<T> = {
  [key in keyof T]: T[key] extends DateTime ? string : T[key] extends Uuid ? string : T[key]
}

export function toJSON<T>(source: T): ToJsonResult<T> {
  if (typeof source !== 'object' || source === null) {
    return source as ToJsonResult<T>
  }
  if (source instanceof DateTime || source instanceof Uuid) {
    return source.valueOf() as ToJsonResult<T>
  }
  const obj = isJsonable(source) ? source.toJSON() : Object.assign({}, source)
  for (const [prop, value] of Object.entries(obj)) {
    obj[prop] = toJSON(value)
  }
  return obj
}
