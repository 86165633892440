import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router'
import { from, Observable, of } from 'rxjs'
import { switchMap, take } from 'rxjs/operators'

import { AuthService } from './auth.service'

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.authService.currentUser$.pipe(
      switchMap((user) => {
        if (!user) {
          return from(this.router.navigateByUrl(`/?returnUrl=${encodeURIComponent(state.url)}`))
        }
        return of(true)
      }),
      take(1),
    )
  }
}
