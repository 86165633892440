import { isStep, Step } from './step'

export type FormControlPathSegment = string | number
export type FormControlPath = string | [string, ...FormControlPathSegment[]]

export interface FormStep extends Step {
  formControlPath: FormControlPath
  requireVisit?: boolean
  forceValue?: unknown
  requireValue?: unknown
}

export function isFormStep(obj: any): obj is FormStep {
  return !!obj?.formControlPath && isStep(obj)
}
