import { PositionDepth, PositionDistance, PositionScroll } from './position-dimensions'

export type PositioningElement = {
  [TKey in PositionDistance | PositionDepth | PositionScroll]: number
} & { ownerDocument: HTMLDocument }

export interface OffsetPositioningElement extends PositioningElement {
  offsetParent: PositioningElement
}

export function isOffsetPositioningElement(obj: any): obj is OffsetPositioningElement {
  return !!obj.offsetParent
}
