import { PerAxis, RelativePosition, Responsive } from '../../positioning'

export enum GuideElementType {
  appRootComponent = 'rootComponent',
  beacon = 'beacon',
  beaconParent = 'beaconParent',
  chapterBeacon = 'chapterBeacon',
  routeComponent = 'routeComponent',
  routerOutlet = 'routerOutlet',
}

export type AxisBounding = PerAxis<GuideElementType>

export interface GuideElementPositioning {
  align?: RelativePosition
  position?: RelativePosition
  boundedBy?: GuideElementType | AxisBounding
  positionBoundedBy?: GuideElementType | AxisBounding
  sizeBoundedBy?: GuideElementType | AxisBounding
  sizeModifier?: number
}

export interface GuideElementLayout {
  layout?: GuideElementPositioning | Responsive<GuideElementPositioning>
}
