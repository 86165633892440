import { Observable, Subject } from 'rxjs'
import { InjectionToken, Provider } from '@angular/core'
import { shareReplay } from 'rxjs/operators'

export interface ErrorHandlerData {
  dismissible: boolean
  message: string
}

export interface ErrorInfo extends Partial<ErrorHandlerData> {
  error: Error
  message?: string
  source?: string
  retry$?: Observable<number>
  handled?: boolean
}

export const Errors$$ = new InjectionToken<Subject<ErrorInfo>>('Errors$$')
export const Errors$ = new InjectionToken<Observable<ErrorInfo>>('Errors$')

export const Errors$$Provider: Provider = {
  provide: Errors$$,
  useFactory: () => new Subject<ErrorInfo>(),
}

export const Errors$Provider: Provider = {
  provide: Errors$,
  useFactory: (errors$$: Subject<ErrorInfo>) => errors$$.pipe(shareReplay(1)),
  deps: [Errors$$],
}

export const ErrorsProviders = [Errors$Provider, Errors$$Provider]
