export enum Breakpoint {
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  lg = 'lg',
  xl = 'xl',
}

export type BreakpointOrder = { [TBrk in Breakpoint]: number }

export const BreakpointOrder: BreakpointOrder = {
  xs: 0,
  sm: 1,
  md: 2,
  lg: 3,
  xl: 4,
}

export const Breakpoints = Object.values(Breakpoint).sort((a, b) => BreakpointOrder[a] - BreakpointOrder[b])

export type PartialResponsive<T> = {
  [TBrk in Breakpoint]?: T
}
export type Responsive<T> = PartialResponsive<T> & { xs: T }

export type ResponsiveInput<T> = T | Responsive<T>
export type ResponsiveInputs<T> = [ResponsiveInput<T>, ...ResponsiveInput<T>[]]

export function isResponsive<T>(obj: any): obj is Responsive<T> {
  return obj && typeof obj.xs !== 'undefined'
}

export function isPartialResponsive<T>(obj: any): obj is PartialResponsive<T> {
  return (
    isResponsive(obj) || (obj && Object.keys(Breakpoint).some((brk) => Object.prototype.hasOwnProperty.call(obj, brk)))
  )
}

export function orderedResponsive<T>(responsive: Responsive<T>): [Breakpoint, T][] {
  const entries = Object.entries(responsive) as [Breakpoint, T][]
  return entries.sort(([brkA], [brkB]) => {
    return BreakpointOrder[brkA] - BreakpointOrder[brkB]
  })
}
