import { Component } from '@angular/core'
import { Observable } from 'rxjs'

import { NavInfo, NavInfoService } from '../shared'

@Component({
  selector: 'app-navigation',
  templateUrl: './app-navigation.component.pug',
  styleUrls: ['./app-navigation.component.scss'],
})
export class AppNavigationComponent {
  public readonly navInfo$: Observable<NavInfo>

  constructor(navInfo: NavInfoService) {
    this.navInfo$ = navInfo.navInfo$
  }
}
