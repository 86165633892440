import { Component, HostBinding } from '@angular/core'

@Component({
  selector: 'modal-footer',
  template: '<ng-content></ng-content>',
  styleUrls: ['./modal-footer.component.scss'],
})
export class ModalFooterComponent {
  @HostBinding('class.modal-footer')
  public readonly modalFooter = true
}
