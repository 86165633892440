import { InjectionToken } from '@angular/core'

import { APP_CONFIG } from '../environments/environment'

export interface AppConfig {
  api: {
    host: string
  }
  firebase: { [key: string]: string }
}

export function appConfigFactory(): AppConfig {
  return APP_CONFIG
}

export const APP_CONFIG_TOKEN = new InjectionToken<AppConfig>('AppConfig')

// IMPORTANT! APP_CONFIG must be exposed via a factory like this, or it won't work correctly
export const APP_CONFIG_PROVIDER = {
  provide: APP_CONFIG_TOKEN,
  useFactory: appConfigFactory,
}
