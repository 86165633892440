import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'

import { AuthInfoComponent } from './auth-info.component'

@NgModule({
  declarations: [AuthInfoComponent],
  exports: [AuthInfoComponent],
  imports: [CommonModule],
})
export class AuthInfoModule {}
