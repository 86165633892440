import { HttpClient } from '@angular/common/http'
import { Inject, Injectable } from '@angular/core'
import { SELF_RELATION } from '@dandi/hal'
import { ModelBuilder } from '@dandi/model-builder'
import { BudgetResource, BudgetItemResource, BudgetItemCreationModel } from '@fmb/models'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import { DandiModelBuilder, toJSON } from '../shared/app-common'

@Injectable({ providedIn: 'root' })
export class BudgetItemEndpoint {
  constructor(private http: HttpClient, @Inject(DandiModelBuilder) private mb: ModelBuilder) {
    this.mapItem = this.mapItem.bind(this)
  }

  public updateItem(item: BudgetItemResource): Observable<BudgetItemResource> {
    const path = item.getLink(SELF_RELATION).href
    return this.http.put(path, item).pipe(map(this.mapItem))
  }

  public createItem(budget: BudgetResource, item: BudgetItemCreationModel): Observable<BudgetItemResource> {
    const path = budget.getLink('items').href
    const request = Object.assign(toJSON(item), {
      budgetId: budget.budgetId.toString(),
    })
    delete request.itemId
    return this.http.post(path, request).pipe(map(this.mapItem))
  }

  public deleteItem(item: BudgetItemResource): Observable<BudgetItemResource> {
    const path = item.getLink(SELF_RELATION).href
    return this.http.delete(path).pipe(map(this.mapItem))
  }

  private mapItem(item: unknown): BudgetItemResource {
    return this.mb.constructModel(BudgetItemResource, item)
  }
}
