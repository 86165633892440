import { Injectable } from '@angular/core'
import { defer, Observable } from 'rxjs'
import { distinctUntilChanged, map, shareReplay } from 'rxjs/operators'

import { RouteHelper } from '../../../shared/app-common'

import { AnyCondition, isLocationCondition, LocationCondition, RoutePath, RoutePathSegment } from '../'

import { ConditionCompletionStateFactory } from './condition-completion-state-factory'

@Injectable()
export class LocationConditionCompletionStateFactory extends ConditionCompletionStateFactory<LocationCondition> {
  constructor(private readonly route: RouteHelper) {
    super()
  }

  protected canHandleCondition(condition: AnyCondition): condition is LocationCondition {
    return isLocationCondition(condition)
  }

  protected createCompleteStream(condition: LocationCondition): Observable<boolean> {
    return defer(() => this.route.routeData$).pipe(
      map((routeData) => this.comparePath(routeData.path, condition.routePath)),
      distinctUntilChanged(),
      shareReplay(1),
    )
  }

  private comparePath(path: string[], expectedPath: RoutePath): boolean {
    if (path.length !== expectedPath.length) {
      return false
    }

    return path.every((segment, index) => this.compareSegment(segment, expectedPath[index]))
  }

  private compareSegment(segment: string, expectedSegment: RoutePathSegment): boolean {
    if (expectedSegment === String) {
      return true
    }
    if (expectedSegment instanceof RegExp) {
      return expectedSegment.test(segment)
    }
    return segment === expectedSegment
  }
}
