import { Injectable, StaticProvider } from '@angular/core'
import { defer, Observable } from 'rxjs'
import { map, shareReplay } from 'rxjs/operators'
import { Actions } from '@ngxs/store'

import { compareArray } from '../guide'
import { ConditionCompletion } from '../guide/condition'
import { ManualStep, StepData } from '../guide/model'
import { RouteHelper } from '../shared/app-common'

@Injectable()
export class NavigateToAccountStepCompletion implements ConditionCompletion {
  public static providers(): StaticProvider[] {
    return [
      {
        provide: NavigateToAccountStepCompletion,
        deps: [Actions, RouteHelper],
      },
    ]
  }

  public static step(step: Partial<StepData<ManualStep>> = {}): StepData<ManualStep> {
    return {
      ...step,
      complete: NavigateToAccountStepCompletion,
      providers: NavigateToAccountStepCompletion.providers(),
    }
  }

  public readonly complete$: Observable<boolean>

  constructor(actions: Actions, route: RouteHelper) {
    this.complete$ = defer(() => {
      const viaRoute$ = route.routeData$.pipe(map((data) => compareArray(data.path, ['account'])))

      return viaRoute$.pipe(shareReplay(1))
    })
  }
}
