import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'

import { ExternalLinkDirective } from './external-link.directive'
import { FocusedInputDirective } from './focused-input.directive'
import { LinkDisabledDirective } from './link-disabled.directive'
import { ToggleComponent } from './toggle.component'

@NgModule({
  declarations: [ExternalLinkDirective, FocusedInputDirective, LinkDisabledDirective, ToggleComponent],
  imports: [CommonModule],
  exports: [ExternalLinkDirective, FocusedInputDirective, LinkDisabledDirective, ToggleComponent],
})
export class AppCommonModule {}
