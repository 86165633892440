import { InjectionToken, Type } from '@angular/core'

import { GuideElementLayout } from './guide-element-type'
import { GuideEntity, SkippableGuideEntity } from './guide-entity'
import { Steps } from './steps'

export type Chapters<TKeys extends string = string> = { readonly [TKey in TKeys]: Chapter }
export interface Chapter<TSteps extends Steps = Steps>
  extends GuideEntity<keyof TSteps>,
    GuideElementLayout,
    SkippableGuideEntity {
  id: string
  component: Type<any>
  beacon: string
  steps?: TSteps
}
export const Chapter = new InjectionToken<Chapter>('Chapter')

export type ChapterData<TChapter extends Chapter = Chapter> = Omit<TChapter, 'id' | 'dependencies'>
