import { Inject } from '@angular/core'
import { ModelBuilder } from '@dandi/model-builder'
import { BudgetResource, BudgetItemResource } from '@fmb/models'
import { Action, State, StateContext, Store } from '@ngxs/store'
import { append, patch, removeItem, updateItem } from '@ngxs/store/operators'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import {
  CreateBudget,
  CreateBudgetItem,
  DeleteBudgetItem,
  LoadBudgets,
  SignOut,
  SimulationComplete,
  UpdateBudgetItem,
} from '../action'
import { DandiModelBuilder } from '../shared/app-common'

import { BudgetEndpoint } from './budget.endpoint'
import { BudgetItemEndpoint } from './budget-item.endpoint'

export interface BudgetStateModel {
  budgetList: BudgetResource[]
  budgetItems: { [budgetId: string]: BudgetItemResource[] }
}

@State<BudgetStateModel>({
  name: 'budgets',
  defaults: {
    budgetList: undefined,
    budgetItems: undefined,
  },
})
export class BudgetState {
  constructor(
    private store: Store,
    private budget: BudgetEndpoint,
    private budgetItem: BudgetItemEndpoint,
    @Inject(DandiModelBuilder) private mb: ModelBuilder,
  ) {}

  @Action(LoadBudgets)
  public loadBudgets(ctx: StateContext<BudgetStateModel>, { me }: LoadBudgets): BudgetStateModel {
    if (!me.budgets) {
      return ctx.setState({
        budgetList: [],
        budgetItems: {},
      })
    }
    const budgetItems = me.budgets.reduce((result, budget) => {
      result[budget.budgetId.toString()] = budget.items
      return result
    }, {})
    const result = ctx.setState({
      budgetList: me.budgets.map((budget) => {
        delete (budget as any)._embedded
        return budget
      }),
      budgetItems,
    })

    if (me.simulation) {
      ctx.dispatch(new SimulationComplete(me.simulation))
    }
    return result
  }

  @Action(CreateBudgetItem)
  public createBudgetItem(
    ctx: StateContext<BudgetStateModel>,
    { budget, itemData }: CreateBudgetItem,
  ): Observable<BudgetStateModel> {
    return this.budgetItem.createItem(budget, itemData).pipe(
      map((updatedItem) =>
        ctx.setState(
          patch({
            budgetItems: patch({
              [updatedItem.budgetId.toString()]: append([updatedItem]),
            }),
          }),
        ),
      ),
    )
  }

  @Action(UpdateBudgetItem)
  public updateBudgetItem(
    ctx: StateContext<BudgetStateModel>,
    { item }: UpdateBudgetItem,
  ): Observable<BudgetStateModel> {
    return this.budgetItem.updateItem(item).pipe(
      map((updatedItem) =>
        ctx.setState(
          patch({
            budgetItems: patch({
              [updatedItem.budgetId.toString()]: updateItem(
                (stateItem) => stateItem.itemId.toString() === updatedItem.itemId.toString(),
                updatedItem,
              ),
            }),
          }),
        ),
      ),
    )
  }

  @Action(DeleteBudgetItem)
  public deleteBudgetItem(
    ctx: StateContext<BudgetStateModel>,
    { item }: DeleteBudgetItem,
  ): Observable<BudgetStateModel> {
    return this.budgetItem.deleteItem(item).pipe(
      map((deletedItem) =>
        ctx.setState(
          patch({
            budgetItems: patch({
              [deletedItem.budgetId.toString()]: removeItem<BudgetItemResource>(
                (stateItem) => stateItem.itemId.toString() === deletedItem.itemId.toString(),
              ),
            }),
          }),
        ),
      ),
    )
  }

  @Action(CreateBudget)
  public createBudget(ctx: StateContext<BudgetStateModel>, { name }: CreateBudget): Observable<BudgetStateModel> {
    return this.budget.create(name).pipe(
      map((updatedBudget) =>
        ctx.setState(
          patch({
            budgetList: append([updatedBudget]),
            budgetItems: patch({
              [updatedBudget.budgetId.toString()]: [] as BudgetItemResource[],
            }),
          }),
        ),
      ),
    )
  }

  @Action(SignOut)
  public signOut(context: StateContext<BudgetStateModel>): void {
    context.setState(undefined)
  }
}
