import { NgModule, Optional, SkipSelf } from '@angular/core'

import { AuthGuard } from './auth.guard'
import { AuthService } from './auth.service'
import { NotAuthGuard } from './not-auth.guard'

@NgModule({
  providers: [AuthGuard, AuthService, NotAuthGuard],
})
export class AuthModule {
  constructor(@Optional() @SkipSelf() parentModule: AuthModule) {
    if (parentModule) {
      throw new Error('AuthModule is already loaded. Import it in the AppModule only')
    }
  }
}
