export type RoutePathSegment = string | StringConstructor | RegExp
export type RoutePath = RoutePathSegment[]

export interface LocationCondition {
  routePath: RoutePath
}

export function isLocationCondition(obj: any): obj is LocationCondition {
  return !!obj?.routePath
}
