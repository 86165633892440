import { Injectable } from '@angular/core'
import { BudgetItemResource, BudgetResource } from '@fmb/models'
import { combineLatest, Observable } from 'rxjs'
import { map, shareReplay, startWith } from 'rxjs/operators'

import { BudgetItemsService, BudgetService } from '../budget'

import { NavInfo } from './nav-info'

@Injectable({ providedIn: 'root' })
export class NavInfoService {
  public readonly navInfo$: Observable<NavInfo>

  constructor(budgetService: BudgetService, budgetItemsService: BudgetItemsService) {
    this.navInfo$ = combineLatest([budgetService.currentBudget$, budgetItemsService.budgetItems$]).pipe(
      map(([budget, items]) => [budget, items || []] as [BudgetResource, BudgetItemResource[]]),
      map(([budget, items]) => ({
        budget,
        items,
        hasItems: !!items.find((i) => i.amount > 0) && !!items.find((i) => i.amount < 0),
      })),
      startWith({ hasItems: false }),
      shareReplay(1),
    )
  }
}
