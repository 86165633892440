import { ModuleWithProviders } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'

import { LazyModule } from '../shared/app-common'

export const routes: Routes = [
  {
    path: 'success',
    loadChildren: (): LazyModule => import('./success/auth-success.module').then((m) => m.AuthSuccessModule),
  },
]

export const routing: ModuleWithProviders = RouterModule.forChild(routes)
