import { ChangeDetectionStrategy, Component, Input } from '@angular/core'

import { ErrorInfo } from '../shared/app-common'

import { AppErrorHandler } from './app-error-handler'

@Component({
  selector: 'app-error',
  templateUrl: './app-error.component.pug',
  styleUrls: ['./app-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppErrorComponent {
  @Input()
  public error: ErrorInfo

  constructor(private readonly errorHandler: AppErrorHandler) {}

  public reload(): void {
    window.location.reload()
  }

  public dismiss(): void {
    this.errorHandler.dismiss()
  }
}
