import { InjectionToken, Injector, StaticProvider, Type } from '@angular/core'
import { defer, Observable } from 'rxjs'

import { AnyCondition, ConditionCompletion } from '../'

import { ConditionCompletionStateFactory } from './condition-completion-state-factory'

export abstract class InjectorCompletionStateFactory<
  TCondition extends AnyCondition
> extends ConditionCompletionStateFactory<TCondition> {
  constructor(protected readonly injector: Injector) {
    super()
  }

  protected createCompleteStream(condition: TCondition): Observable<boolean> {
    return defer(() => {
      const injector = Injector.create({ providers: this.getProviders(condition) || [], parent: this.injector })
      const completinator = injector.get(this.getServiceClass(condition))
      return completinator.complete$
    })
  }

  protected abstract getProviders(condition: TCondition): StaticProvider[]
  protected abstract getServiceClass(
    condition: TCondition,
  ): InjectionToken<ConditionCompletion> | Type<ConditionCompletion>
}
