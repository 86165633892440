import { InjectionToken, Type } from '@angular/core'
import { Observable } from 'rxjs'

export type ServiceStreams<TService> = {
  [TProp in keyof TService]: TService[TProp] extends Observable<infer TEntry> ? Observable<TEntry> : never
}
export type StreamType<TService, TProp extends keyof ServiceStreams<TService>> = TService[TProp] extends Observable<
  infer TEntry
>
  ? TEntry
  : never
export type ServiceConditionFn<TService, TProp extends keyof ServiceStreams<TService>> = (
  entry: StreamType<TService, TProp>,
) => boolean

export const SourceService = new InjectionToken<any>('ServiceStepCompletion::SourceService')
export const ServiceProp = new InjectionToken<string | number | symbol>('ServiceStepCompletion::ServiceProp')
export const ServiceConditionFn = new InjectionToken<ServiceConditionFn<unknown, never>>(
  'ServiceStepCompletion::ConditionFn',
)

export interface ServiceConditionDef<TService = unknown, TProp extends keyof ServiceStreams<TService> = never> {
  type: Type<TService>
  prop: TProp
  conditionFn: ServiceConditionFn<TService, TProp>
}

export interface ServiceCondition<TService = unknown, TProp extends keyof ServiceStreams<TService> = never> {
  service: ServiceConditionDef<TService, TProp>
}

export function isServiceConditionDef(obj: any): obj is ServiceConditionDef<any, any> {
  return !!obj && !!obj.type && !!obj.prop && typeof obj.conditionFn === 'function'
}

export function isServiceCondition(obj: any): obj is ServiceCondition<any, any> {
  return isServiceConditionDef(obj?.service)
}

export function serviceCondition<TService, TProp extends keyof ServiceStreams<TService>>(
  type: Type<TService>,
  prop: TProp,
  conditionFn: ServiceConditionFn<TService, TProp>,
): ServiceCondition<TService, TProp> {
  return { service: { type, prop, conditionFn } }
}
