import {
  AfterViewInit,
  ApplicationRef,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  ElementRef,
  HostBinding,
  HostListener,
  Inject,
  InjectFlags,
  OnDestroy,
  Optional,
  ViewChild,
  ViewContainerRef,
} from '@angular/core'
import { RouterOutlet } from '@angular/router'
import { combineLatest, fromEvent, merge, NEVER, Observable, ReplaySubject, Subject } from 'rxjs'
import {
  debounceTime,
  delay,
  map,
  mapTo,
  mergeMap,
  pluck,
  share,
  shareReplay,
  startWith,
  switchMap,
  take,
  tap,
} from 'rxjs/operators'

import {
  Axis,
  getPerAxis,
  PerAxis,
  PositionEdge,
  PositioningElement,
  PositioningService,
  RelativePosition,
  ResponsiveService,
} from '../positioning'

import { AsyncBinder, ChangeDetectorHost } from './async-binder'
import { ChapterStepButtonDirective } from './chapter-step-button.directive'
import { GuideBeacon } from './guide-beacon'
import { GuideBeaconsService } from './guide-beacons.service'
import {
  AxisBounding,
  ButtonStepType,
  Chapter,
  ContentStep,
  GuideElementPositioning,
  GuideElementType,
  isStepButtonStep,
  StepButton,
} from './model'
import { exists, sustained } from './rxjs'
import { Story } from './story'

let instanceId = 0

function px(value: number): string {
  if (typeof value === 'undefined') {
    return undefined
  }
  return `${value}px`
}

interface PositioningElements {
  arrow: PositioningElement
  boundingElement: PerAxis<PositioningElement>
}

interface PositioningOffsets {
  step: number
  arrow: number
}

interface ArrowOffsets {
  arrowDepth: number
  arrowMargin: number
}

// noinspection AngularMissingOrInvalidDeclarationInModule
@Component({
  selector: 'guide-chapter-step',
  templateUrl: './chapter-step.component.pug',
  styleUrls: ['./chapter-step.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChapterStepComponent implements ChangeDetectorHost, AfterViewInit, OnDestroy {
  @HostBinding('attr.id')
  public elementId: string

  @HostBinding('style.maxWidth')
  public maxWidth: string

  @HostBinding('class.show')
  public show: boolean

  public top: string
  public left: string

  public readonly arrowOffsetTop$: Observable<string>
  public readonly arrowOffsetLeft$: Observable<string>
  public readonly click$: Observable<MouseEvent>
  public readonly renderButton: StepButton

  @HostBinding('class')
  private cssClass = 'step'

  @HostBinding('attr.role')
  private readonly role = 'tooltip'

  @HostBinding('style.transform')
  private get transform(): string {
    return `translate(${this.left}, ${this.top})`
  }

  @HostBinding('style.width')
  private width: string

  @ViewChild('arrow', { static: true })
  private set arrowRef(elementRef: ElementRef) {
    console.log('[ChapterStepComponent] arrowRef', elementRef)
    this.arrowRef$$.next(elementRef)
  }

  @ViewChild(ChapterStepButtonDirective)
  private set buttonDirective(button: ChapterStepButtonDirective) {
    this.buttonDirective$$.next(button)
  }

  private readonly layout$: Observable<GuideElementPositioning>
  private readonly position$: Observable<RelativePosition>
  private readonly alignment$: Observable<RelativePosition>
  private readonly element: PositioningElement
  private readonly positionBoundingElementType$: Observable<AxisBounding>
  private readonly sizeBoundingElementType$: Observable<AxisBounding>
  private readonly sizeBoundingElements$: Observable<PerAxis<PositioningElement>>
  private readonly positionBoundingElements$: Observable<PerAxis<PositioningElement>>
  private readonly containerElement: PositioningElement
  private readonly rootComponentRef: ComponentRef<unknown>
  private readonly asyncBinder: AsyncBinder<this>
  private readonly arrowRef$$ = new ReplaySubject<ElementRef>(1)
  private readonly buttonDirective$$ = new ReplaySubject<ChapterStepButtonDirective>(1)
  private readonly arrow$: Observable<PositioningElement> = this.arrowRef$$.pipe(
    map((ref) => ref || {}),
    pluck('nativeElement'),
  )
  private readonly positioningElements$: Observable<PositioningElements>
  private readonly resize$$ = new Subject<void>()

  private get routerOutletComponentRef(): ComponentRef<unknown> {
    return ((this.routerOutlet as unknown) as { activated: ComponentRef<unknown> })?.activated
  }

  constructor(
    private readonly beacons: GuideBeaconsService,
    private readonly positioning: PositioningService,
    private readonly responsive: ResponsiveService,
    @Inject(GuideBeacon) private readonly beacon: GuideBeacon,
    @Inject(Story) public readonly story: Story,
    @Inject(Chapter) public readonly chapter: Chapter,
    @Inject(ContentStep) public readonly step: ContentStep,
    private readonly elementRef: ElementRef,
    public readonly changeDetectorRef: ChangeDetectorRef,
    @Inject(ApplicationRef) appRef: ApplicationRef,
    private readonly viewContainerRef: ViewContainerRef,
    @Optional() private readonly routerOutlet: RouterOutlet,
  ) {
    this.asyncBinder = new AsyncBinder(this)
    this.element = elementRef.nativeElement
    this.renderButton = this.getStepButton()
    const [rootComponentRef] = appRef.components
    this.rootComponentRef = rootComponentRef
    this.containerElement = rootComponentRef.injector.get(
      ViewContainerRef,
      undefined,
      InjectFlags.Host,
    ).element.nativeElement

    this.elementId = `step-${step.id}-${(instanceId++).toString(8).padStart(4, '0')}`
    this.layout$ = this.responsive.for(this.step.content.layout, chapter.layout, {}).pipe(
      tap((layout) => console.log('[ChapterStepComponent] layout$', layout)),
      shareReplay(1),
    )
    this.position$ = this.layout$.pipe(
      map((layout) => layout.position || RelativePosition.top),
      tap((position) => {
        this.cssClass = ['step', `step--${position}`].join(' ')
      }),
      tap((position) => console.log('[ChapterStepComponent] position$', position)),
      shareReplay(1),
    )
    this.alignment$ = this.layout$.pipe(
      mergeMap((layout) =>
        layout.align ? sustained(layout.align) : this.position$.pipe(map((position) => position.defaultAlignment)),
      ),
      tap((position) => console.log('[ChapterStepComponent] alignment$', position)),
      shareReplay(1),
    )

    this.width = 'auto'

    this.click$ = this.buttonDirective$$.pipe(switchMap((button) => (button ? button.click$ : NEVER)))

    this.positionBoundingElementType$ = this.layout$.pipe(
      map((layout) =>
        getPerAxis<GuideElementType>(layout.positionBoundedBy || layout.boundedBy, GuideElementType.chapterBeacon),
      ),
    )
    this.sizeBoundingElementType$ = this.layout$.pipe(
      map((layout) =>
        getPerAxis<GuideElementType>(layout.sizeBoundedBy || layout.boundedBy, GuideElementType.chapterBeacon),
      ),
    )
    this.sizeBoundingElements$ = this.getContentBoundingElements(this.sizeBoundingElementType$).pipe(shareReplay(1))
    this.positionBoundingElements$ = this.getContentBoundingElements(this.positionBoundingElementType$).pipe(
      shareReplay(1),
      tap((els) => console.log('[ChapterStepComponent] positionBoundingElements$', els)),
    )
    this.positioningElements$ = combineLatest([
      this.arrow$,
      this.positionBoundingElements$,
      this.resize$$.pipe(startWith(undefined as void)),
      this.getScrollStream(this.beacon.element).pipe(startWith(undefined as void)),
      // route.routeData$,
    ]).pipe(
      debounceTime(0),
      map(([arrow, boundingElement]) => ({ arrow, boundingElement })),
      tap((els) => console.log('[ChapterStepComponent] positioningElements$', els)),
      shareReplay(1),
    )

    const positioningState$ = combineLatest([this.positioningElements$, this.position$, this.alignment$]).pipe(
      tap(([els, position, alignment]) =>
        console.log('[ChapterStepComponent] positioningState$', { els, position, alignment }),
      ),
      share(),
    )

    const xOffsets$ = positioningState$.pipe(
      map(([els, position, alignment]) => this.getOffsets(position, alignment, Axis.x, els)),
      tap((offsets) => console.log('[ChapterStepComponent] xOffsets$', offsets)),
      shareReplay(1),
    )
    const yOffsets$ = positioningState$.pipe(
      map(([els, position, alignment]) => this.getOffsets(position, alignment, Axis.y, els)),
      tap((offsets) => console.log('[ChapterStepComponent] yOffsets$', offsets)),
      shareReplay(1),
    )

    this.arrowOffsetLeft$ = xOffsets$.pipe(pluck('arrow'), map(px))
    this.arrowOffsetTop$ = yOffsets$.pipe(pluck('arrow'), map(px))

    this.asyncBinder
      .bind(
        'maxWidth',
        combineLatest([this.sizeBoundingElements$, this.layout$]).pipe(
          tap(([element, layout]) =>
            console.log('[ChapterStepComponent] sizeBoundingElements$ layout$', { element, layout }),
          ),
          map(([element, layout]) => px(element.x.offsetWidth * (layout.sizeModifier || 1))),
        ),
      )
      .bind('left', xOffsets$.pipe(pluck('step'), map(px)))
      .bind('top', yOffsets$.pipe(pluck('step'), map(px)))
      .bind('show', yOffsets$.pipe(take(1), delay(10), mapTo(true)))
  }

  public ngAfterViewInit(): void {
    this.asyncBinder.init()
  }

  public ngOnDestroy(): void {
    this.asyncBinder.unbind()
    this.arrowRef$$.complete()
    this.buttonDirective$$.complete()
    this.resize$$.complete()
  }

  @HostListener('window:resize')
  private onResize(): void {
    this.resize$$.next()
  }

  private getScrollStream(target: HTMLElement): Observable<void> {
    let current = target
    const targets: HTMLElement[] = [target]
    while (current.offsetParent) {
      current = current.offsetParent as HTMLElement
      targets.push(current)
    }
    return merge(...targets.map((target) => fromEvent(target, 'scroll'))).pipe(mapTo(undefined), share())
  }

  private getContentBoundingElements(
    boundingElements$: Observable<AxisBounding>,
  ): Observable<PerAxis<PositioningElement>> {
    return boundingElements$.pipe(
      mergeMap((boundingElements) => {
        console.log('[ChapterStepComponent] getContentBoundingElements boundingElements$', boundingElements)
        return combineLatest([
          this.getContentBoundingElement(boundingElements.x),
          this.getContentBoundingElement(boundingElements.y),
        ])
      }),

      map(([x, y]) => ({ x, y })),
    )
  }

  private getContentBoundingElement(boundingElement: GuideElementType): Observable<PositioningElement> {
    if (typeof boundingElement === 'string') {
      switch (boundingElement) {
        case GuideElementType.routerOutlet:
        case GuideElementType.routeComponent:
          if (!this.routerOutlet) {
            throw new Error(
              'GuideElementType.routerOutlet and GuideElementType.routeComponent cannot be used with beacons that ' +
                'are rendered outside of a route component',
            )
          }
      }

      switch (boundingElement) {
        case GuideElementType.beacon:
          return sustained(this.beacon.element as PositioningElement)
        case GuideElementType.beaconParent:
          return sustained((this.beacon.element.offsetParent as unknown) as PositioningElement)
        case GuideElementType.chapterBeacon:
          return this.beacons.get(this.chapter.beacon).pipe(exists(), pluck('element'))
        case GuideElementType.routerOutlet:
          return sustained(this.routerOutletComponentRef?.location?.nativeElement)
        case GuideElementType.routeComponent:
          return sustained(this.routerOutletComponentRef?.location?.nativeElement)
        case GuideElementType.appRootComponent: {
          const appRef = this.beacon.viewContainerRef.injector.get(ApplicationRef)
          const [rootComponentRef] = appRef.components
          return sustained(rootComponentRef.location.nativeElement)
        }
      }
    }
    throw new Error(`Unsupported bounding type ${boundingElement}`)
  }

  private getOffsets(
    position: RelativePosition,
    alignment: RelativePosition,
    axis: Axis,
    { arrow, boundingElement }: PositioningElements,
  ): PositioningOffsets {
    const isOnAxis = position.axis === axis
    const isLeadingEdge = position.edge === PositionEdge.leading
    let arrowOffset: number = undefined
    let arrowCenteringOffset = 0

    console.log('[Position/ChapterStepComponent] getOffsets', (arrow || {})[axis.depth], { arrow, axis, isLeadingEdge })

    const offsets = this.positioning.getOffsets(
      position,
      alignment,
      axis,
      this.containerElement,
      boundingElement[axis.name],
      this.beacon.element,
      this.element,
    )

    const { arrowDepth, arrowMargin } = this.getArrowOffsets(axis, arrow)
    const minArrowOffset = arrowMargin

    if (isOnAxis) {
      // space the component out from the bounding element by 75% the depth of the arrow
      offsets.position += arrowDepth * 0.75 * (isLeadingEdge ? -1 : 1)
    } else {
      // offset the arrow's position so that it points at the middle of the beacon element
      const beaconDepth = this.beacon.element[axis.depth]

      if (alignment.edge === PositionEdge.leading) {
        arrowOffset = arrowDepth / 2 - arrowMargin
        // arrowOffset = arrowDepth / 2 - beaconDepth / 2
        arrowCenteringOffset = arrowOffset + arrowDepth / 2 + arrowMargin
      } else {
        arrowOffset = this.element[axis.depth] - beaconDepth / 2
      }

      if (offsets.boundingEdge) {
        // if the component is offset due to exceeding the bounds of the bounding container, also offset
        // the arrow so it still lines up to point at the middle of the beacon element
        // however, the arrow must retain a minimum offset so that it not positioned past the curve of the corner, so
        // the arrowOffset adjustment must be balanced against minArrowOffset
        arrowOffset += -offsets.boundingEdge - arrowCenteringOffset
        arrowCenteringOffset = 0
        if (arrowOffset < minArrowOffset) {
          arrowCenteringOffset = minArrowOffset - arrowOffset
          arrowOffset = minArrowOffset
        }
      }

      console.log('[ChapterStepComponent] getOffsets arrowOffsets', {
        arrowOffset,
        arrowMargin,
        arrowDepth,
        arrowCenteringOffset,
        beaconDepth,
        offsets,
      })
    }

    return {
      step: offsets.position - arrowCenteringOffset + (offsets.boundingEdge || 0),
      arrow: arrowOffset,
    }
  }

  private getArrowOffsets(axis: Axis, arrow: PositioningElement): ArrowOffsets {
    if (arrow) {
      const arrowDepth = arrow[axis.depth]
      const arrowMargin = parseFloat(getComputedStyle((arrow as unknown) as Element).getPropertyValue(axis.margin))
      return { arrowDepth, arrowMargin }
    }
    return { arrowDepth: 0, arrowMargin: 0 }
  }

  private getStepButton(): StepButton {
    if (!isStepButtonStep(this.step)) {
      return undefined
    }

    return this.step.button === ButtonStepType.step ? { type: ButtonStepType.step } : this.step.button
  }
}
