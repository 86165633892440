import { Injectable } from '@angular/core'
import { Actions, Store } from '@ngxs/store'
import { ActionContext, ActionStatus } from '@ngxs/store/src/actions-stream'
import { defer, Observable } from 'rxjs'
import { filter, mapTo, take } from 'rxjs/operators'

import {
  ActionCondition,
  AnyCondition,
  DispatchActionCondition,
  isActionCondition,
  isDispatchActionCondition,
} from '../'

import { ConditionCompletionStateFactory } from './condition-completion-state-factory'

@Injectable()
export class ActionConditionCompletionStateFactory extends ConditionCompletionStateFactory<ActionCondition> {
  constructor(private readonly store: Store, private readonly actions: Actions) {
    super()
  }

  protected canHandleCondition(condition: AnyCondition): condition is ActionCondition {
    return isActionCondition(condition)
  }

  protected createCompleteStream(condition: ActionCondition): Observable<boolean> {
    if (isDispatchActionCondition(condition)) {
      return this.dispatchActionStepComplete(condition)
    }
    return this.actionConditionComplete(condition)
  }

  protected dispatchActionStepComplete(step: DispatchActionCondition): Observable<boolean> {
    return defer(() => {
      const action = new step.actionType(...step.actionData)
      return this.store.dispatch(action)
    }).pipe(mapTo(true))
  }

  protected actionConditionComplete(step: ActionCondition): Observable<boolean> {
    const actionFilter = step.actionFilter
      ? (action: any): boolean => Object.keys(step.actionFilter).every((key) => action[key] === step.actionFilter[key])
      : (): boolean => true

    // ensure that the step's dependencies are complete before looking for a matching action
    return defer(() =>
      this.actions.pipe(
        filter<ActionContext>(
          (action) =>
            action.status === ActionStatus.Successful &&
            action.action instanceof step.actionType &&
            (!step.actionFilter || actionFilter(action.action)),
        ),
      ),
    ).pipe(mapTo(true), take(1))
  }
}
