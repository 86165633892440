import { ChapterState } from '../model'

import { ChapterStateAction } from './chapter-state-action'

export class StartChapter extends ChapterStateAction {
  public static readonly type = StartChapter.getType('Start Step')

  constructor(userKey: string, storyId: string, chapterId: string, state?: Partial<ChapterState>) {
    super({ startedOn: Date.now() }, userKey, storyId, chapterId, state)
  }
}
