import { NEVER, Observable } from 'rxjs'

import { AvailableStep } from '../available-step'
import { AnyStep } from '../model'

export abstract class StepCompletionStateFactory<TStep extends AnyStep = AnyStep> {
  public getComplete(available: AvailableStep): Observable<boolean> {
    const canHandleStep = this.canHandleStep(available)
    console.log(`[${this.constructor.name}] canHandleStep`, available.step.id, canHandleStep)
    return this.canHandleStep(available) ? this.createCompleteStream(available) : NEVER
  }

  protected abstract canHandleStep(available: AvailableStep): available is AvailableStep<TStep>
  protected abstract createCompleteStream(available: AvailableStep<TStep>): Observable<boolean>
}
