import { ChangeDetectionStrategy, Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core'

let modalId = 0

@Component({
  selector: 'modal',
  templateUrl: './modal.component.pug',
  styleUrls: ['./modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalComponent implements OnInit, OnDestroy {
  @Input()
  public title: string

  @Input()
  public centered: boolean

  @Input()
  public scrollable: boolean

  @HostBinding('class.show')
  @Input()
  public show: boolean

  @HostBinding('attr.role')
  public readonly role = 'dialog'

  @HostBinding('class.modal')
  public readonly modal = true

  @HostBinding('tabindex')
  public readonly tabindex = -1

  @HostBinding('style.display')
  public get display(): string {
    return this.show ? 'flex' : ''
  }

  public readonly hostId: string

  @HostBinding('attr.aria-labelledby')
  public readonly titleElementId: string

  constructor() {
    const id = modalId++
    this.hostId = id.toString(16).padStart(5, '0')
    this.titleElementId = `modal-title-${this.hostId}`
  }
  public ngOnInit(): void {
    console.log('[ModalComponent] ngOnInit')
  }

  public ngOnDestroy(): void {
    console.log('[ModalComponent] ngOnDestroy')
  }
}
