import { ActionCondition, isActionCondition } from './action-condition'
import { isLocationCondition, LocationCondition } from './location-condition'
import { isManualCondition, ManualCondition } from './manual-condition'
import { isServiceCondition, ServiceCondition } from './service-condition'

export type AnyCondition = ActionCondition | LocationCondition | ManualCondition | ServiceCondition<any, any>

export function isAnyCondition(obj: any): obj is AnyCondition {
  return isActionCondition(obj) || isLocationCondition(obj) || isManualCondition(obj) || isServiceCondition(obj)
}
