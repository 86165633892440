import { Injectable } from '@angular/core'
import { AbstractControl, FormArray, FormGroup, FormGroupDirective } from '@angular/forms'
import { Observable } from 'rxjs'
import { distinctUntilChanged, filter, finalize, map, mergeMap, shareReplay, startWith, tap } from 'rxjs/operators'

import { AvailableStep } from '../available-step'
import { FormControlPath, FormControlPathSegment, FormStep, isFormStep } from '../model'
import { sustained } from '../rxjs'

import { StepCompletionStateFactory } from './step-completion-state-factory'

@Injectable()
export class FormStepCompletionStateFactory extends StepCompletionStateFactory<FormStep> {
  protected canHandleStep(available: AvailableStep): available is AvailableStep<FormStep> {
    return isFormStep(available.step)
  }

  protected createCompleteStream(available: AvailableStep<FormStep>): Observable<boolean> {
    const [formControlName, remainingPath] = this.getNameAndPath(available.step.formControlPath)
    console.log(
      '[FormContentStepCompletionStateFactory] createCompleteStream',
      available.step.id,
      available.step.beacon,
    )

    return available.beacon$.pipe(
      mergeMap((beacon) => {
        if (!beacon) {
          return sustained(false)
        }
        const formGroup = beacon.viewContainerRef.injector.get(FormGroupDirective)
        const form = formGroup.form
        const control = this.getControl(form, formControlName, ...remainingPath)
        if (!control) {
          throw new Error(`No control named '${formControlName}'`)
        }
        if (typeof available.step.forceValue !== 'undefined') {
          control.setValue(available.step.forceValue)
        }
        return this.controlValidity(available.step, control).pipe(
          filter(() => {
            if (typeof available.step.forceValue === 'undefined' || control.value === available.step.forceValue) {
              return true
            }
            control.setValue(available.step.forceValue)
            return false
          }),
          map(
            (valid) =>
              valid &&
              (typeof available.step.requireValue === 'undefined' || control.value === available.step.requireValue),
          ),
        )
      }),
      startWith(false),
      distinctUntilChanged(),
      tap((valid) =>
        console.log('[FormContentStepCompletionStateFactory.controlValidity] valid', available.step.id, valid),
      ),
      shareReplay(1),
      finalize(() => {
        console.log(
          '[FormContentStepCompletionStateFactory] createCompleteStream finalize',
          available.step.id,
          available.step.beacon,
        )
      }),
    )
  }

  private controlValidity(step: FormStep, control: AbstractControl): Observable<boolean> {
    if (control.stateChanges) {
      return control.stateChanges.pipe(
        map((state) => {
          if (!state.valid || state.focused) {
            return false
          }
          if (step.requireVisit) {
            return state.visited
          }
          return state.pristine || state.blurred
        }),
      )
    }
    return control.valueChanges.pipe(map(() => control.valid))
  }

  private getNameAndPath(formControlPath: FormControlPath): [string, FormControlPathSegment[]] {
    if (typeof formControlPath === 'string') {
      return [formControlPath, []]
    }
    const [name, ...path] = formControlPath
    return [name, path]
  }

  private getControl(
    parent: AbstractControl,
    formControlName: string,
    ...formControlPath: FormControlPathSegment[]
  ): AbstractControl {
    if (formControlName === '.') {
      return parent
    }
    return this.getNestedControl(parent, [], formControlName, formControlPath)
  }

  private getNestedControl(
    parent: AbstractControl,
    parentPath: FormControlPathSegment[],
    formControlName: FormControlPathSegment,
    formControlPath: FormControlPathSegment[],
  ): AbstractControl {
    if (typeof formControlName === 'undefined') {
      return parent
    }

    let controlPath: string
    let result: AbstractControl
    if (typeof formControlName === 'string') {
      controlPath = `.${formControlName}`
      if (parent instanceof FormGroup) {
        result = parent.controls[formControlName]
      } else {
        throw new Error(
          `Got a string path, but the control at formGroup${parentPath.join('')}${controlPath} is not a FormGroup`,
        )
      }
    }

    if (typeof formControlName === 'number') {
      controlPath = `[${formControlName}]`
      if (parent instanceof FormArray) {
        result = parent.controls[formControlName]
      } else {
        throw new Error(
          `Got a numeric path, but the control at formGroup${parentPath.join('')}${controlPath} is not a FormArray`,
        )
      }
    }

    if (!result) {
      throw new Error(`Could not find a control at formGroup${parentPath.join('')}${controlPath}`)
    }

    return this.getNestedControl(result, [...parentPath, controlPath], formControlPath.shift(), formControlPath)
  }
}
