import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'

import { ModalComponent } from './modal.component'
import { ModalDialogComponent } from './modal-dialog.component'
import { ModalBodyComponent } from './modal-body.component'
import { ModalFooterComponent } from './modal-footer.component'
import { ModalTitleComponent } from './modal-title.component'

@NgModule({
  declarations: [ModalBodyComponent, ModalComponent, ModalDialogComponent, ModalFooterComponent, ModalTitleComponent],
  imports: [CommonModule],
  exports: [ModalBodyComponent, ModalComponent, ModalFooterComponent, ModalTitleComponent],
})
export class ModalModule {}
