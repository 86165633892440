import { Injectable, StaticProvider, Type } from '@angular/core'

import { AnyCondition, ServiceCondition, isServiceCondition, ConditionCompletion } from '../'

import { InjectorCompletionStateFactory } from './injector-completion-state-factory'
import { ServiceConditionCompletion } from './service-condition-completion'

@Injectable()
export class ServiceConditionCompletionStateFactory extends InjectorCompletionStateFactory<ServiceCondition<any, any>> {
  protected canHandleCondition(condition: AnyCondition): condition is ServiceCondition<any, any> {
    return isServiceCondition(condition)
  }

  protected getProviders(condition: ServiceCondition<any, any>): StaticProvider[] {
    return ServiceConditionCompletion.providers(condition.service)
  }

  protected getServiceClass(): Type<ConditionCompletion> {
    return ServiceConditionCompletion
  }
}
