import { Injectable } from '@angular/core'

import { Axis } from './axis'
import { PositionEdge } from './position-dimensions'
import { isOffsetPositioningElement, PositioningElement } from './positioning-element'
import { RelativePosition } from './relative-position'

export interface PositioningOffsets {
  position: number
  container: number
  leadingEdge?: number
  boundingElement?: number
  boundingEdge?: number
  alignmentElement?: number
  alignmentOffset?: number
}

interface PositioningState {
  debug: any[]
  offsets: PositioningOffsets
  axis: Axis
  isOffAxis: boolean
  isOnAxis: boolean
  container: PositioningElement
  boundingElement: PositioningElement
  target: PositioningElement
  alignmentElement: PositioningElement
  containerBoundsOffset: number
}

@Injectable({ providedIn: 'root' })
export class PositioningService {
  // TODO: make this an observable, take input observables to trigger recalculation
  public getOffsets(
    position: RelativePosition,
    alignment: RelativePosition,
    axis: Axis,
    container: PositioningElement,
    boundingElement: PositioningElement,
    alignmentElement: PositioningElement,
    target: PositioningElement,
  ): PositioningOffsets {
    const isOnAxis = position.axis === axis
    const isOffAxis = position.axis !== axis

    console.log(`[PositioningService] getOffsets ${position} ${alignment} ${axis}`, {
      position,
      axis,
      container,
      boundingElement,
      alignmentElement,
      target,
      isOnAxis,
      isOffAxis,
    })

    console.log(`[PositioningService] getOffsets ${position} ${alignment} ${axis} containerBoundsOffset`, {
      container,
      boundingElement,
    })
    const containerBoundsOffset = this.getDistance(axis, container, boundingElement)

    const debug: any[] = [
      { containerBoundsOffset, source: { method: 'getDistance', axis, container, boundingElement } },
    ]
    const offsets: PositioningOffsets = { position: containerBoundsOffset, container: containerBoundsOffset }

    const state: PositioningState = {
      alignmentElement,
      axis,
      boundingElement,
      container,
      containerBoundsOffset,
      debug,
      isOffAxis,
      isOnAxis,
      offsets,
      target,
    }

    if (isOnAxis) {
      const isLeadingEdge = position.edge === PositionEdge.leading
      if (isLeadingEdge) {
        offsets.leadingEdge = -target[axis.depth]
        debug.push({
          leadingEdge: offsets.leadingEdge,
          condition: { isOffAxis, isLeadingEdge },
          source: { [`-target.${axis.depth}`]: offsets.leadingEdge, target, boundingElement },
        })
      } else {
        offsets.leadingEdge = boundingElement[axis.depth]
        debug.push({
          leadingEdge: offsets.leadingEdge,
          condition: { isOffAxis, isLeadingEdge },
          source: { [`boundingElement.${axis.depth}`]: offsets.leadingEdge, boundingElement },
        })
      }
      offsets.position += offsets.leadingEdge
    }

    if (isOffAxis) {
      console.log(`[PositioningService] getOffsets ${position} ${alignment} ${axis} boundingElementOffset`, {
        boundingElement,
        alignmentElement,
      })
      offsets.boundingElement = this.getDistance(axis, boundingElement, alignmentElement)
      debug.push({
        boundingElement: offsets.boundingElement,
        condition: { isOffAxis },
        source: { method: 'getDistance', axis, boundingElement, alignmentElement },
      })
      offsets.position += offsets.boundingElement

      const isTrailingEdge = alignment.edge === PositionEdge.trailing
      if (isTrailingEdge) {
        offsets.alignmentOffset = -target[axis.depth] + alignmentElement[axis.depth]
        debug.push({
          alignmentOffset: offsets.alignmentOffset,
          condition: { isOffAxis, isTrailingEdge },
          source: { [`target.${axis.depth}`]: target[axis.depth] },
        })
        offsets.position += offsets.alignmentOffset
      } else {
        offsets.alignmentElement = alignmentElement[axis.depth] / 2
        debug.push({
          alignmentElement: offsets.alignmentElement,
          condition: { isOffAxis, isTrailingEdge },
          source: { [`alignmentElement.${axis.depth}`]: alignmentElement[axis.depth], alignmentElement },
        })
        offsets.position += offsets.alignmentElement
      }

      this.calculateBoundingOffset(boundingElement, state)
    }

    console.log(`[PositioningService] getOffsets return ${axis}`, {
      axis,
      boundingElement,
      container,
      target,
      offsets,
      [`boundingElement.${axis.depth}`]: boundingElement[axis.depth],
      [`boundingElement.${axis.scroll}`]: boundingElement[axis.scroll],
      debug,
    })

    return offsets
  }

  public getDistance(axis: Axis, offsetTarget: PositioningElement, child: PositioningElement): number {
    const debug = Object.entries(axis).reduce(
      (result, [key, value]) => {
        if (key === 'name') {
          return result
        }
        result[`child.${value}`] = child[value]
        result[`offsetTarget.${value}`] = offsetTarget[value]
        return result
      },
      {
        offsetTarget,
        child,
        'child.offsetParent': isOffsetPositioningElement(child) ? child.offsetParent : undefined,
        'offsetTarget.offsetParent': isOffsetPositioningElement(offsetTarget) ? offsetTarget.offsetParent : undefined,
      },
    )

    const childScroll = -child[axis.scroll]
    const totalScroll = childScroll - offsetTarget[axis.scroll]

    if (!isOffsetPositioningElement(child)) {
      console.log('[PositioningService] getDistance', axis.distance, 'no child.offsetParent', 0, debug)
      return totalScroll
    }

    if (isOffsetPositioningElement(offsetTarget) && child.offsetParent === offsetTarget.offsetParent) {
      console.log('[PositioningService] getDistance', axis.distance, 'shared offsetParent', 0, debug)
      return totalScroll
    }

    if (child === offsetTarget) {
      console.log('[PositioningService] getDistance', axis.distance, 'child is offsetTarget', 0, debug)
      return totalScroll
    }

    console.log('[PositioningService] getDistance', axis.distance, child[axis.distance], debug)
    return child[axis.distance] + childScroll + this.getDistance(axis, offsetTarget, child.offsetParent)
  }

  private calculateBoundingOffset(
    boundingElement: PositioningElement,
    { offsets, target, axis, isOffAxis, debug, containerBoundsOffset }: PositioningState,
  ): void {
    const trailingEdge = offsets.position + target[axis.depth]
    const maxTrailingEdge = containerBoundsOffset + boundingElement[axis.depth]
    const isSmallerThanBoundingElement = target[axis.depth] < boundingElement[axis.depth]

    if (trailingEdge > maxTrailingEdge) {
      const boundingOffset = maxTrailingEdge - trailingEdge
      debug.push({
        boundingOffset,
        condition: {
          isOffAxis,
          trailingEdge,
          maxTrailingEdge,
          ['trailingEdge > maxTrailingEdge']: trailingEdge > maxTrailingEdge,
          isSmallerThanBoundingElement,
        },
        source: {
          offset: offsets.position,
          [`target.${axis.depth}`]: target[axis.depth],
          containerBoundsOffset,
          [`boundingElement.${axis.depth}`]: boundingElement[axis.depth],
          isSmallerThanBoundingElement,
          maxTrailingEdge,
        },
      })
      offsets.boundingEdge = boundingOffset
    } else {
      debug.push({
        boundingOffset: undefined,
        condition: {
          isOffAxis,
          trailingEdge,
          maxTrailingEdge,
          ['trailingEdge > maxTrailingEdge']: trailingEdge > maxTrailingEdge,
          isSmallerThanBoundingElement,
        },
        source: {
          offset: offsets.position,
          [`target.${axis.depth}`]: target[axis.depth],
          containerBoundsOffset,
          [`boundingElement.${axis.depth}`]: boundingElement[axis.depth],
          isSmallerThanBoundingElement,
          maxTrailingEdge,
        },
      })
    }
  }
}
