import { GuideEntity } from './guide-entity'

export interface Step<TDeps extends string = string> extends GuideEntity<TDeps> {
  unskippable?: boolean
  beacon?: string
  forceChapterComplete?: true
}

export function isStep(obj: any): obj is Step {
  return !!obj?.id
}
