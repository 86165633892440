import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
} from '@angular/core'
import { Store } from '@ngxs/store'
import { Observable, Subject } from 'rxjs'
import { distinctUntilChanged, scan, take, tap } from 'rxjs/operators'

import { CompleteChapter } from './action'
import { GuideService } from './guide.service'
import { Chapter } from './model'
import { Story } from './story'

@Component({
  selector: 'guide-chapter-step-menu',
  templateUrl: './chapter-step-menu.component.pug',
  styleUrls: ['./chapter-step-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChapterStepMenuComponent implements OnDestroy {
  @Input()
  public chapter: Chapter

  @Input()
  public story: Story

  @Input()
  public disabled: boolean

  public readonly click$: Observable<MouseEvent>
  public readonly showOptions$: Observable<boolean>

  public get canSkipChapter(): boolean {
    return this.chapter && !this.chapter.unskippable
  }

  public get canSkipStory(): boolean {
    return this.story && !this.story.unskippable
  }

  @HostBinding('class.btn')
  @HostBinding('class.btn-link')
  @HostBinding('class.btn-link-info')
  @HostBinding('class.chapter-step-menu')
  private readonly hostClasses = true

  @HostBinding('class.chapter-step-menu--show')
  private get show(): boolean {
    return this.canSkipChapter || this.canSkipStory
  }

  @HostBinding('class.chapter-step-menu--active')
  private active = false

  private readonly click$$ = new Subject<MouseEvent>()

  constructor(private readonly store: Store, private readonly guide: GuideService, elementRef: ElementRef) {
    this.click$ = this.click$$.asObservable()
    this.showOptions$ = this.click$.pipe(
      scan((show: boolean, e: MouseEvent) => {
        const target: HTMLElement = e.target as HTMLElement
        if (target === elementRef.nativeElement || target.parentElement === elementRef.nativeElement) {
          return !show
        }
        return false
      }, false),
      distinctUntilChanged(),
      tap((active) => (this.active = active)),
    )
  }

  public ngOnDestroy(): void {
    this.click$$.complete()
  }

  public skipStory(): void {
    Object.values(this.story.chapters).forEach((chapter) => this.skipChapter(chapter))
  }

  public skipChapter(chapter?: Chapter): void {
    this.guide
      .dispatch(CompleteChapter, this.story.id, (chapter || this.chapter).id)
      .pipe(take(1))
      .subscribe()
  }

  @HostListener('window:click', ['$event'])
  private onClick(e: MouseEvent): void {
    if (!this.disabled) {
      this.click$$.next(e)
    }
  }
}
