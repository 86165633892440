import { BudgetResource, BudgetItemCreationModel } from '@fmb/models'

import { AnalyticsAction } from '../shared/analytics'

export class CreateBudgetItem extends AnalyticsAction {
  public static type = '[BudgetItem] Create'

  constructor(public readonly budget: BudgetResource, public readonly itemData: BudgetItemCreationModel) {
    super({ category: 'BudgetItem', action: 'Create' })
  }
}
