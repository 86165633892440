import { HTTP_INTERCEPTORS, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Inject, Injectable, Provider } from '@angular/core'
import { Observable, Subject, throwError, timer } from 'rxjs'
import { finalize, last, map, mapTo, mergeMap, retryWhen, share, takeWhile } from 'rxjs/operators'

import { APP_CONFIG_TOKEN, AppConfig } from '../config'

import { ErrorInfo, Errors$$ } from './app-common'

const MAX_RETRY_DURATION_MINUTES = 5
const MAX_RETRY_DURATION_SECONDS = MAX_RETRY_DURATION_MINUTES * 60
const RETRY_DELAY_SECONDS = 10
const MAX_RETRIES = Math.ceil(MAX_RETRY_DURATION_SECONDS / RETRY_DELAY_SECONDS)

@Injectable({ providedIn: 'root' })
export class ApiHostInterceptor implements HttpInterceptor {
  public static readonly provider: Provider = {
    provide: HTTP_INTERCEPTORS,
    multi: true,
    useExisting: ApiHostInterceptor,
  }

  constructor(
    @Inject(APP_CONFIG_TOKEN) private config: AppConfig,
    @Inject(Errors$$) private errors$$: Subject<ErrorInfo>,
  ) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let retries = MAX_RETRIES
    return next
      .handle(
        req.clone({
          url: `${this.config.api.host}${req.urlWithParams.startsWith('/') ? '' : '/'}${req.urlWithParams}`,
        }),
      )
      .pipe(
        retryWhen((errors$) =>
          errors$.pipe(
            mergeMap((err) => {
              if (err.status !== 0 || !--retries) {
                return throwError(err)
              }
              const retry$ = timer(0, 1000).pipe(
                map((value) => RETRY_DELAY_SECONDS - value),
                takeWhile((v) => v >= 0), // +2 so that it emits a value immediately, and counts down to 0
                share(),
              )
              const errorInfo: ErrorInfo = {
                error: err,
                dismissible: false,
                retry$,
              }
              this.errors$$.next(errorInfo)
              return retry$.pipe(last(), mapTo(err))
            }),
          ),
        ),
        finalize(() => {
          // automatically clear the error if there was one
          if (retries !== MAX_RETRIES) {
            this.errors$$.next(undefined)
          }
        }),
      )
  }
}
